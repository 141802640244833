// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui.scss";
// Temp fix for reactstrap
// @import '@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

body {
    background: #fff;
    color: #999;
}
.f-12 {
    font-size: 12px;
}

/* Progress Bar */
.progress-type-01 {
    border-radius: 50px;
    .progress-bar {
        border-radius: 50px;
    }
}
.progress-type-02.progress {
    height: 20px;
    border-top-right-radius:  25px;
    border-bottom-right-radius:  25px;
    background: #20a8d8;
    .progress-bar {
        background: #818586;
    }
}
.progress-sm {
    height: 12px;
}
.text-over-progress {
    .progress {
        .progress-bar {
            color: transparent;
        }
        ~ span {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 2px;
            color: #fff;
            font-size: 12px;
            text-align: center;
        }
    }
}

.header-fixed {
    .app-header {
        height: 85px;
    }
} 
.card-group .card {
    margin-bottom: 0;
    height: 100%;
}

.no-gutters.new-group-plan {
    margin-right: -6px;
    margin-left: -6px;
  
    > .col,
    > [class*="col-"] {
      padding-right: 6px;
      padding-left: 6px;
    }
  }

.w-150px {
    width: 150px;
}

// @media (min-width: 992px) {
//     .header-fixed {
//         .app-body {
//             margin-top: 95px;
//         }
//     }
// }

.header-fixed {
    .app-body {
        margin-top: 95px;
    }
}


.cursor-nodrop {
    cursor: no-drop;
    pointer-events: none;
}
.displaynone
{
    display: none;
}
.teamgoal{
    padding: 2px 6px;
    color: white;
    border-radius: 5px;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    width: 95%;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-family: 'PingFangScSemiBold';
    font-size: 16px;
}
.guideLineTab{
    max-height: 350px;
    overflow: auto;  
}
.new-vms-changes {
    .CP-Dashboard-card {
        border: 4px solid #0197cc !important;
    }
    .dashboard-header-card-1, .dashboard-header-card-3{
        padding-right: 0px;
        padding-left: 0px;
      }
}