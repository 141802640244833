
.login-section {
    background: #464848!important;
    height: 100vh;
    overflow: auto;
}
.login-container {
    width: 236px;
    /* font-family: 'SFMono-Regular'; */
}
.login-container.login-signup {
    width: 55%;
}
.login-logo {
    width: 300px;
    height: 85px;
    margin: inherit;
    margin-left: -30px;
}
.login-form-fields .form-control {
    font-size: 16px;
    background: #6E7F8D;
    color: #fff !important;
    border-color: transparent;
}
.login-form-fields .form-control:focus {
    background: #80929E;
    outline: 0;
    box-shadow: none;
    border-color: transparent;
}
.login-form-fields .btn.btn-primary:not(.btn-sm) {
    background: #0197CC;
    font-size: 16px;
    color: #fff;
    opacity: 1;
}
.login-form-fields .btn.btn-link {
    font-size: 16px;
    color: #ACB7BD;
}

.login-form-fields input:-webkit-autofill,
.login-form-fields input:-webkit-autofill:hover, 
.login-form-fields  input:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #6E7F8D inset;
  transition: background-color 5000s ease-in-out 0s;
}

.login-form-fields input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}
.login-form-fields input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}
.login-form-fields input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
}
.login-form-fields .reset-text {
    font-size: 17px;
    line-height: 24px;
}
