// Colors declarations
$colors: (
  OrangeBadgeBorder: #ffa500,
  OrangeBadgebackground: #fff2e9,
  PinkBadgeBorder: #e868a5,
  PinkBadgebackground: #fff0f5,
  PrimaryBlue: #0197cc,
  FoundationGrey: #8e9194,
  GrowthPlanPurple: #a25ddc,
  GrowthPlanBlue: #0091ff,
  GrowthPlanOrange: orange,
  GrowthPlanLightGreen: #00e15a,
  GrowthPlanDarkeBlue: #0086c0,
  PageBackground: #f4f4f4,
  BodyBackground: #fafbfd,
  GreyBackGround: #f4f5fa,
);

$fontSize: (
  H1: 38px,
  H2: 24px,
  H3: 20px,
  H4: 19px,
  H5: 18px,
  H6: 16px,
  H1weight: 300,
  H2weight: 300,
);

@font-face {
  font-family: "PingFangScRegular";
  src: url(../assets/fonts/PingFangSCRegular.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "PingFangScMedium";
  src: url(../assets/fonts/PingFangSCMedium.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PingFangScSemiBold";
  src: url(../assets/fonts/PingFangSCSemiBold.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SFMono-Regular";
  font-style: normal;
  font-weight: normal;
  src: url(../assets/fonts/SFMonoRegular.woff) format("woff");
}

// Mixins
@mixin center-section() {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

:root {
  --cui-progress-bar-bg: #20a8d8;
  --cui-primary: #20a8d8;
  --cui-body-color: #989898;
  --cui-btn-color: #ffffff;
}
// Here you can add other styles

body {
  // font-family: 'PingFangScRegular';
  font-size: 16px;
  color: #989898;

  font-family: acumin-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
}
h1 {
  // font-family: 'PingFangScMedium';
  font-size: 38px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-style: normal;
}
h2 {
  //font-family: 'PingFangScSemiBold';
  font-size: 24px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-style: normal;
  font-weight: 700;
}
h3 {
  //font-family: 'PingFangScSemiBold';
  font-size: 20px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-style: normal;
  font-weight: 700;
}
h4 {
  //font-family: 'PingFangScSemiBold';
  font-size: 19px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-style: normal;
  font-weight: 700;
}

h5 {
  // font-family: 'PingFangScSemiBold';
  font-size: 18px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-style: normal;
  font-weight: 700;
}
h6 {
  //font-family: 'PingFangScSemiBold';
  font-size: 16px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-style: normal;
  font-weight: 700;
}
.f-14 {
  font-size: 14px;
}
p {
  font-size: 18px;
}
.text-primary {
  color: #0197cc !important;
}
.btn {
  &.btn-gray {
    color: black;
    background-color: rgb(220, 220, 220);
    border-color: rgb(220, 220, 220);
    border-radius: 4px;
    padding: 11px 10px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    width: 100%;
    &:hover {
      border-color: gainsboro;
      border-width: 1px;
      -webkit-box-shadow: 0px 2px 4px gainsboro;
      box-shadow: 0px 2px 4px gainsboro;
      background-color: #dcdcdc9e;
      color: black;
    }
    &:focus {
      border-color: gainsboro;
      border-width: 1px;
      -webkit-box-shadow: 0px 2px 4px gainsboro;
      box-shadow: 0px 2px 4px gainsboro;
      background-color: #dcdcdc9e;
      color: black;
    }
    &:active {
      border-color: rgb(220, 220, 220);
      // box-shadow: inset 2px 2px 10px #076789;
      -webkit-transition-duration: 0.3s; /* Safari */
      transition-duration: 0.3s;
    }
    &:disabled {
      border-radius: 4px;
      color: #fff;
      font-size: 18px;
      background-color: rgb(220, 220, 220);
      border-color: rgb(220, 220, 220);
      opacity: 0.6;
      padding: 10px 24px;
    }
  }
}
// Button
.btn {
  &.btn-primary {
    background: map-get($colors, PrimaryBlue) !important;
    border-radius: 4px;
    background-color: #0197cc !important;
    border-color: #0197cc !important;
    color: #fff !important;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    &:not(.btn-sm) {
      font-size: 18px;
      padding: 10px 24px;
    }
    &.btn-sm {
      font-size: 14px;
      padding: 5px 12px;
    }
    &:hover {
      border-color: #076789;
      border-width: 1px;
      box-shadow: 0px 2px 4px #8e9194;
    }
    &:focus {
      color: #fff;
      // font-size: 18px;
      background-color: #0197cc;
      border-color: #fff;
      // border-width: 1px;
    }
    &:active {
      border-color: #076789;
      box-shadow: inset 2px 2px 10px #076789;
      -webkit-transition-duration: 0.3s; /* Safari */
      transition-duration: 0.3s;
    }
    &:disabled {
      border-radius: 4px;
      color: #fff;
      font-size: 18px;
      background-color: #0197cc;
      border-color: #0197cc;
      opacity: 0.6;
      padding: 10px 24px;
    }
  }
}

.btn.btn-primary.btn-bottom-circle {
  border-radius: 50%;
  padding: 8px;
  width: 72px;
  height: 72px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  box-shadow: 0px 3px 3px 0 rgba(32, 33, 36, 0.28);
  position: relative;
  bottom: 0.5%;
  @include center-section();
}
.btn-primary {
  background-color: #0197cc !important;
  border-color: #0197cc !important;
  color: #fff !important;
}

.bg-palette-blue {
  background-color: map-get($colors, GrowthPlanBlue) !important;
}
.palette-blue {
  color: map-get($colors, GrowthPlanBlue) !important;
}
.bg-palette-purple {
  background-color: map-get($colors, GrowthPlanPurple) !important;
}
.palette-purple {
  color: map-get($colors, GrowthPlanPurple) !important;
}

.bg-palette-blue {
  background-color: map-get($colors, GrowthPlanBlue) !important;
}
.palette-blue {
  color: map-get($colors, GrowthPlanBlue) !important;
}
.bg-palette-orange {
  background-color: map-get($colors, GrowthPlanOrange) !important;
}
.palette-orange {
  color: map-get($colors, GrowthPlanOrange) !important;
}
.bg-palette-green {
  background-color: map-get($colors, GrowthPlanLightGreen) !important;
}
.palette-green {
  color: map-get($colors, GrowthPlanLightGreen) !important;
}
.error {
  width: 80%;
  margin: 8px;
  color: rgb(200, 0, 0);
  height: 32px;
}
.card {
  box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.p-r {
  padding-right: 0px;
}
.p-l {
  padding-left: 0px;
}
i.fa:before {
  font-family: FontAwesome;
}
.table_view .progress {
  margin: 0px !important;
}
.table_view td p:first-child {
  display: inline-block;
  width: 279px;
  overflow: hidden !important;
  background-color: rgb(45, 188, 225);
  padding: 2px 6px;
  color: white;
  border-radius: 5px;
  margin: 0;
  text-align: left;
  white-space: nowrap;
  // width: 140px;
  // width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "PingFangScSemiBold";
  font-size: 16px;
}

.table_view tbody tr td:first-child {
  background-color: rgb(45, 188, 225);
  padding: 2px;
  width: 5px !important;
}
.table_view table {
  width: 100%;
  float: left;
}
.List_view p {
  text-align: left;
  margin-left: 0px;
  width: 100%;
  float: left;
  margin: auto;
}
.ses_label {
  width: 100%;
  float: left;
}
.progress {
  background: #cecece;
  padding: 0;
}
// .table_view .progress {
//     // border: 1px solid #4dbd74;
//     background: #cecece;
// }
.List_view .progress {
  width: 100%;
  float: left;
}
.table_view tbody td {
  padding: 5px 0px;
  background-color: #f0f0f0;
  border: 1px solid #fff;
}
.ses_label p {
  font-size: 11px;
  margin-bottom: 0px;
}
.List_view .progress {
  background-color: #0197cc;
  border-left: 0px;
  border-right: 0px;
}
.List_view h3 {
  color: #0197cc;
  display: flex;
}

.though_pad h5 {
  background-color: #0197cc;
  width: 100%;
  float: left;
  text-align: center;
  color: #fff;
  padding: 10px 0px;
  margin: 10px 0px;
}
.sidebar_dashboard {
  width: 100%;
  float: left;
  margin: 1em 0;
  color: #797979;
}
.DropdownToggle {
  width: 0px;
  height: 34px;
  &.btn-secondary {
    color: #9aa4ae;
    border-color: #9aa4ae;
  }
}

.GoalDropdownMenu {
  &.dropdown-menu {
    min-width: 0rem;
    border: none;
    color: none;
  }
}
.GoalDropdownItem {
  &.dropdown-item {
    padding: 0px 0px 0px 0px;
    border-bottom: none;
    color: none;
    outline: none;
  }
}
.Goalstart {
  display: flex;
}
// .upcomeing_sess ul {
//     padding: 0px 16px;
//     list-style: none;
// }
// .sidebar_dashboard .upcomeing_sess h5 {
//     margin: 20px 10px;
//     text-align: left;
//     margin-left: 0px;
// }
// .upcomeing_sess li {
//     margin-bottom: 10px;
// }
// .upcomeing_sess li p:nth-child(3) span {
//     color: #38bb3d;
// }
// .upcomeing_sess li p:nth-child(3),.upcomeing_sess li p:nth-child(2) {
//     font-size: 10px;
// }
// .upcomeing_sess li p:first-child {
//     font-size: 16px;
//     color: #6F6F6F;
// }
// .upcomeing_sess ul li::before {
//     content: "\2022";
//     color: #0197CC;
//     font-size: 20px;
//     font-weight: bold;
//     display: inline-block;
//     width: 1em;
//     margin-top: -6px;
//     margin-left: -1em;
//     float: left;
// }
// .upcomeing_sess p {
//     margin: auto;
// }
.duedteoffer {
  padding: 3px 5px;
  color: white;
  font-weight: 600;
}
.duedates {
  font-size: 18px;
}
.GrowthproGress .gp-label {
  font-size: 18px;
  white-space: nowrap;
}
.Growthdate_dateformat {
  width: 34%;
}
.upcoming-sessions {
  position: relative;
  // border-left: 4px solid red;
  // &:before {
  //     content: "\f111";
  //     font-family: 'FontAwesome';
  //     position: absolute;
  //     left: 0;
  //     font-size: 8px;
  //     top: 3px;
  //     color: #0197CC;
  // }
  li,
  .btn {
    font-size: 11px;
    line-height: 13px;
  }
}

.though_pad {
  color: rgba(121, 121, 121, 0.5);
}
.though_pad input {
  width: 100%;
  float: left;
  border: none;
  opacity: 0.4;
}
.though_pad textarea {
  opacity: 0.4;
}
.though_pad h4 {
  margin: 10px;
  opacity: 0.4;
  font-size: 16px;
  font-weight: 400;
  color: #5c6873;
}
.though_pad p {
  margin: auto;
  font-size: 12px;
  width: 100%;
  float: left;
}
.table_view .progress {
  // width: 183px;
  // float: left;
  width: -moz-calc(100% - 83px);
  width: -webkit-calc(100% - 83px);
  width: calc(100% - 83px);
  float: left;
}
.progresspercentage {
  width: 83px;
}
.table_view tbody tr td:nth-child(2),
.table_view tbody tr td:nth-child(3) {
  // width: 120px;
  width: 227px;
}
.table_view tbody td {
  text-align: center;
}
.toogle_swith {
  padding: 10px;
  background-color: #fbf7f7;
}
.chart-wrapper_toolbar input:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.chart-wrapper_toolbar button,
.chart-wrapper_toolbar input,
i.fa.fa-filter {
  margin: 10px 0px;
}
i.fa.fa-filter {
  padding: 3px;
  font-size: 28px;
}
.chart-wrapper_toolbar {
  width: 100%;
  float: left;
  padding: 9px 0px;
}
.col-md-6 {
  float: left;
}
.col-md-3 {
  max-width: 25%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.btn-success {
  color: white;
}
.toogle_swith i {
  padding-right: 10px;
}
.toogle_swith i:first-child {
  background-color: white;
  padding: 10px;
  margin-right: 10px;
}
.toogle_swith i span {
  margin-left: 10px;
}
.ses_label .progress {
  height: 0.65rem;
}
.chart-wrapper_toolbar input {
  border-radius: 18px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 470px;
}
.though_pad button,
.though_pad button:hover,
.chart-wrapper_toolbar button {
  border: 1px solid #0197cc;
  background-color: #0197cc;
  color: #fff;
}
.recent_news {
  // margin: 15px 0px;
  // width: 100%;
  // float: left;
  // padding-bottom: 14px;
  // border-bottom: 1px solid #ccc;
  // p {
  //     line-height: 16px;
  // }
  .delete-icon {
    margin-top: -5px;
  }
  button.btn-light {
    border: 1px solid #0197cc !important;
    padding: 4px 10px;
    background: #fff !important;
  }
}

// .recent_news div:last-child {
//     border: none;
// }
// .recent_news p {
//     margin: auto;
// }
// .recent_news div p:last-child {
//     margin: 10px 0px 0px 0px;
// }
.though_pad input:focus,
.though_pad textarea:focus {
  outline: -webkit-focus-ring-color auto 0px;
  resize: none;
  box-shadow: none;
}

.sidebar_dashboard {
  a.btn-light {
    border: 1px solid #0197cc !important;
    padding: 4px 10px;
    background: #fff !important;
  }
}
.sidebar_dashboard .calendar i {
  padding: 15px 20px;
  background-color: #0197cc;
  color: #fff;
  width: 50px;
  border-radius: 5px;
}
.sidebar_dashboard span#today_date {
  font-size: 18px;
  margin: 10px;
}

.List_view h3:before {
  // content: "\f107";
  font-family: FontAwesome;
  font-size: 17px;
  border-radius: 31px;
  padding: 1px 4px;
  background-color: #0197cc;
  color: #fff;
  margin-right: 8px;
  height: 18px;
  width: 18px;
}

.app-nav-wizard {
  display: inline-block;
  text-align: center;
  width: 100%;
}
.app-nav-wizard .steps {
  width: 33%;
  display: inline-block;
  text-align: center;
  position: relative;
  transition: all 0.5s ease;
}
.app-nav-wizard .steps:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 17%;
  left: 54%;
  width: 92%;
  height: 5px;
  background: #20a8d8;
}
.app-nav-wizard .steps:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 21%;
  left: 55%;
  width: 0%;
  // height: 2px;
}
.app-nav-wizard .steps .stage {
  width: 30px;
  display: inline-flex;
  height: 30px;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #20a8d8;
  color: #fff;
}
.app-nav-wizard .steps .stage i {
  font-size: 1.8em;
}
.app-nav-wizard .steps p {
  margin: 10px 0;
}
.app-nav-wizard .steps .ipv-disabled {
  position: relative;
}
.app-nav-wizard .steps .ipv-disabled::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100px;
  background: #c5bfbf;
  transform: rotate(45deg);
}
.app-nav-wizard .steps.active {
  cursor: pointer;
}
.app-nav-wizard .steps.active .stage {
  color: #20a8d8;
  border: 4px solid #20a8d8;
  position: relative;
  z-index: 9;
}
.app-nav-wizard .steps.active p {
  color: #20a8d8;
}
.app-nav-wizard .steps.completed {
  cursor: pointer;
}
.app-nav-wizard .steps.completed .stage {
  background: #20a8d8;
  color: #fff;
  border: 2px solid #20a8d8;
}
.app-nav-wizard .steps.completed:not(:last-child)::before {
  width: 58%;
  // height: 2px;
  background: #20a8d8;
  z-index: 9;
}
.app-nav-wizard .steps.completed p {
  color: #20a8d8;
}
.app-nav-wizard .steps.completed i {
  color: #fff;
}
// .modal{
//     display: flex !important;
//     align-items: center;
// }
.space-wrapper {
  white-space: nowrap;
}
.gpContainer {
  height: 100%;
  // max-height: 700px;
  //overflow: auto;
}
.goalAction {
  // width: 300px;
  position: relative;
  .goalList {
    height: 175px;
    overflow-y: scroll;
    // border: 1px solid #cccccc;
    padding: 8px;
    position: absolute;
    ul {
      list-style-type: none;
    }
  }
}

.action-list {
  button {
    width: 225px;
    text-align: left;
    i {
      float: right;
    }
  }
}
.addGoal {
  border: 1px dashed #ddd;
  padding: 4px;
  background: white;
}

.tab-list {
  border: none;
  padding-left: 0;
  margin-bottom: 25px;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active span {
  // background-color: white;
  border: solid #0197cc;
  border-width: 0 0 1px 0;
  // padding: 0 2em 0.3em 2em;
  padding: 7px 0px;
}
.user-bio-tabs {
  padding: 0 40px 40px;
  box-shadow: none;
  background: #e8e8e8;
  border: none;
  border-radius: 4px;

  .tab_container {
    box-shadow: none;
    background: #e8e8e8;
    border: none;
    border-radius: 4px;
    padding: 2em 6em;
  }

  .tab-content {
    background: #fff;
    border: none;
    padding: 2em 6em;
    box-shadow: 0 0.1rem 0.8rem rgba(0, 0, 0, 0.15);
    text-align: center;
    border-radius: 8px;
  }
}

.rbc-calendar {
  height: 500px !important;
}

.table-drag-select td {
  border: 2px solid transparent !important;
  line-height: 1.5rem !important;
}

.table-drag-select td.cell-selected {
  background-color: #34b0dc;
}
.show-on-micromentor {
  height: 30px;
}
.meeting_card {
  &.card-header-micro-mendor {
    border-left: none !important;
    .card-header {
      // background: #8f9295;
      h5.text-primary {
        color: #454545 !important;
      }
      .show-on-micromentor {
        height: 30px;
      }
    }
  }
  &.card-header-growth-champ {
    .card-header {
      background: transparent;
      border: 0;
    }
    .show-on-micromentor {
      display: none;
    }
  }
  button.btn-light {
    box-shadow: 0px 2px 4px 1px #999;
  }
  .meeting_box1 {
    .circle {
      border-radius: 100%;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
}
.table-drag-select td.cell-selected {
  background-color: #34b0dc !important;
}
.badge-orange {
  background: map-get($colors, OrangeBadgeBackground);
  border: 1px solid map-get($colors, OrangeBadgeBorder);
  padding: 5px 10px;
  color: map-get($colors, OrangeBadgeBorder);
  margin-right: 10px;
}
.badge-pink {
  background: map-get($colors, PinkBadgeBackground);
  border: 1px solid map-get($colors, PinkBadgeBorder);
  padding: 5px 10px;
  color: map-get($colors, PinkBadgeBorder);
  margin-right: 10px;
}
.list-calendar {
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    padding: 0 7px;
    display: inline-block;
    vertical-align: middle;
    a {
      color: map-get($colors, FoundationGrey);
      text-decoration: none;
    }
  }
  .active {
    //border-bottom:1px solid map-get($colors, PrimaryBlue);
    // padding-bottom: 10px;
  }
}
a {
  text-decoration: none;
}
.c-pointer {
  cursor: pointer;
}
.template-bg {
  background: map-get($colors, PageBackground);
}
body {
  background: map-get($colors, BodyBackground);
}
.main {
  .container-fluid {
    padding: inherit;
  }
}
.gray-box {
  background: map-get($colors, GreyBackGround);
  padding: 2rem;
  margin: 2rem 0;
  p {
    margin-bottom: 0px;
  }
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.chat_send {
  background: map-get($colors, GreyBackGround);
  input {
    border: none;
    background: none;
  }
  .form-control:focus {
    background: none;
    box-shadow: none;
    border: 0;
  }
  button {
    background: none;
    border: none;
    .fa-send:before {
      color: #fd00ff;
    }
  }
}

.chat_messages {
  height: 300px;
}

.sidebar_notes {
  float: left;
  .btn-light {
    border: 1px solid #0197cc !important;
    padding: 4px 10px;
    background: #fff !important;
    color: #0197cc !important;
  }
}

.message_right {
  text-align: right;
  .rounded-left {
    border-top-left-radius: 1.25rem !important;
    border-bottom-left-radius: 1.25rem !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: 01.25rem !important;
  }
}

.message_left {
  text-align: left;
  .rounded-right {
    border-top-right-radius: 1.25rem !important;
    border-bottom-right-radius: 1.25rem !important;
  }
  .rounded-bottom {
    border-bottom-left-radius: 01.25rem !important;
  }
}
@media print {
  @page {
    size: landscape;
  }
}
@media (min-width: 992px) {
  .header-fixed .app-body {
    margin-top: 85px !important;
  }
  .header-fixed .app-header {
    position: fixed;
    z-index: 98;
    width: 100%;
  }
}

.text-picker {
  color: #7c7c7c;
}
.bg-info {
  background: map-get($colors, PrimaryBlue) !important;
  font-size: 16px;
  font-family: "PingFangScMedium";
}

.micromentorpopover {
  max-width: 800px;
  right: 0;
}

.card-body-50 {
  padding: 50px;
}

// .gp-details-container {
//     margin: 25px;
// }

.bg-blue-gradiant {
  background: rgb(1, 151, 204);
  background: linear-gradient(
    180deg,
    rgba(1, 151, 204, 1) 0%,
    rgba(102, 175, 201, 1) 100%
  );
}

.mt-6 {
  margin-top: 5.5rem !important;
}

.position-relatives {
  position: relative !important;
}

.search-box {
  border-radius: 0.7rem;
  background-color: #f0f0f0;
}

.search-box:focus {
  background-color: #f0f0f0;
  outline: -webkit-focus-ring-color auto 0px;
  box-shadow: none;
}

.chart-container {
  display: inline-block;
  width: 125px;
  height: 125px;
  overflow: hidden;
}

.pyramid-chart-container {
  padding: 20px;

  &:hover {
    .pyramid-chart-slice {
      /*margin: 2px 0;*/
    }
  }

  .pyramid-chart-slice {
    //transition: all .5s ease;

    &:hover {
      //  margin-left: 30px;
    }
  }
}

ul.growth-tracker {
  list-style: none;
  display: inline-block;
  li:first-child::before {
    content: "\2022";
    color: #ffce56;
    font-size: 32px;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-top: -4px;
    margin-left: -1em;
  }

  li:nth-child(2):before {
    content: "\2022";
    color: #36a2eb;
    font-size: 32px;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-top: -4px;
    margin-left: -1em;
  }

  li:last-child::before {
    content: "\2022";
    color: #4dbd74;
    font-size: 32px;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-top: -4px;
    margin-left: -1em;
  }
}
.gp-details-container {
  padding: 25px 0;
  background-color: #f4f5fa;
  max-width: 1200px;
  margin: 0 auto;
}

.popup-content {
  align-items: flex-end;
}

.color-picklist {
  background-color: transparent !important;
  width: 16px;
  height: 16px;
  border-radius: 25px;
  border: none;
}
.color-picklist:after {
  // content: "\F107";
  // font-family: FontAwesome;
  // font-size: 17px;
  // color: #fff;
  background-image: url(../assets/img/icons/palette-light.svg);
  background-size: contain;
  background-repeat: no-repeat;
  right: 5px;
  margin: 0 auto;
  background-position: center;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  left: auto;
  content: "";
}
.color-picklist:hover:after {
  background-image: url(../assets/img/icons/palette-lighthHover.svg);
}

.color-picklist:focus + .color-picker {
  opacity: 1;
}

.color-picker {
  position: absolute;
  left: auto;
  max-width: 17px;
  min-width: 95px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  z-index: 1;
  border-radius: 8px 0px 8px 8px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  top: 51px;
  right: 0;
}

.color-picker:before {
  background-color: #fff;
  border: 1px solid #ddd;
  height: 30px;
  padding: 5px;
  border-radius: 14px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
  content: "";
  width: 30px;
  position: absolute;
  left: auto;
  top: -30px;
  right: -1px;
}

.color-picker .color {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  margin: 5px;
  border: 0;
}

.color-picklist:focus {
  outline: none;
}

.selected-color {
  width: 20px;
  background-color: #ddd;
  height: 20px;
  border-radius: 20px;
  display: inline-block;
  position: absolute;
  left: auto;
  top: -24px;
  right: 4px;
}

.leftSideVideo {
  width: 817px !important;
  height: 612px !important;
  padding: 120px 1.2em 0 !important;
  border-radius: 3px;
  .App-mask {
    background-color: transparent;
  }
  .video-container.small {
    width: 100px !important;
    border: none !important;
    height: 100px !important;
    border-radius: 50% !important;
    bottom: auto !important;
    top: 10px !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    .OT_publisher {
      border-radius: 50% !important;
      video {
        border-radius: 50% !important;
      }
    }
    .OT_widget-container {
      background-color: transparent !important;
    }
  }
}
.App-control-container {
  &.videoControls {
    width: 817px !important;
    .ots-video-control {
      background-color: #181e2a !important;
      border-color: #181e2a !important;
      margin: 5px !important;
    }
    .ots-video-control.circle.end-call {
      background-color: #ff5551 !important;
      border-color: #ff5551 !important;
    }
    .ots-video-control.circle.video,
    .ots-video-control.circle.end-call,
    .ots-video-control.circle.archiving {
      //margin-left: 10px !important;
    }
  }
}

.chatSection {
  h5 {
    color: #797979;
  }
  .sidebar_notes {
    padding: 0px 1rem !important;
    border-top: none !important;
    h3 {
      border-top: 1px solid #c8ced3 !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      padding: 1rem 0px !important;
    }
  }
  span#today_date {
    color: #797979;
  }
  .ots-text-chat .ots-messages-holder {
    height: 310px;
    // overflow: initial;
    margin-right: -20px;
  }
  div#otsChatWrap {
    overflow: hidden;
  }
  .ots-text-chat .ots-messages-holder .ots-message-item .ots-user-name-initial {
    width: 35px;
    height: 35px;
    line-height: 37px;
    font-size: 18px;
  }
  .ots-text-chat {
    border: none;
    margin-top: 0px;
    padding-bottom: 20px;
    .ots-messages-holder .ots-message-item.ots-message-sent .ots-item-text {
      background-color: #f0f3f5 !important;
      color: #fff;
      font-size: 16px;
      //font-family: 'PingFangScRegular';
      font-family: acumin-pro, sans-serif;
      font-style: normal;
      font-weight: 400;
      background-color: #20a8d8 !important;
      &::before {
        border-color: #20a8d8 transparent transparent transparent;
      }
    }
    .ots-send-message-box .active {
      background-color: transparent;
    }
    .ots-messages-holder .ots-message-item {
      padding: 0 35px;
    }
    .ots-item-text {
      width: auto;
    }
  }
  .ots-send-message-box {
    height: auto !important;
    border: none !important;
    background-color: #f4f5fa;
    width: calc(100% - 20px) !important;
    margin: 0 auto;
    input {
      padding: 8px !important;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 0.8rem;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 0.8rem;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 0.8rem;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 0.8rem;
      }
    }
    button {
      width: 45px;
      height: 100% !important;
      background-image: none;
      border-left: none !important;
      &:hover {
        background-color: transparent;
      }
      &::after {
        content: "\F1D8";
        color: #fd00ff;
        font-size: 1.33333333em;
        line-height: 0.75em;
        vertical-align: -15%;
        font-family: fontawesome;
      }
    }
  }
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.link-style {
  background: none !important;
  border: none;
  padding: 0 !important;
  border-radius: none;

  /*optional*/
  //  font-family:arial,sans-serif; /*input has OS specific font-family*/
  //   color:#069;
  //   text-decoration:underline;
  cursor: pointer;
}
.react-datepicker__input-container input {
  background-image: url(../assets/img/icons/calendar.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% center;
}

/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.LoadingDot {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.LoadingDot > div {
  width: 12px;
  height: 12px;
  background-color: #0197cc;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.LoadingDot .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.LoadingDot .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.btn {
  .btn-trans {
    background: transparent;
    outline: 0;
    border: 0;
  }
}

.soh-icon {
  .btn-trans {
    opacity: 0;
    transition: 0.5s;
  }
  &:hover {
    .btn-trans {
      opacity: 1;
    }
  }
}

/*Get Help front desk for temp*/
#fc_frame,
#fc_frame.fc-widget-normal {
  right: initial !important;
  left: 0px !important;
  bottom: 0px !important;
}

//   @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//     .modal {
//     .modal-dialog {
//     &.modal-xl {
//     width: 1140px !important;
//     }
//     }
//     }
//     }
//     .modal-start{
//         .modal {
//             align-items: flex-start;
//         }
//     }

.cui-icon:before {
  font-family: CoreUI-Icons-Linear-Free !important;
}

@mixin gutter-width($size) {
  &.row {
    margin-right: -$size;
    margin-left: -$size;

    > .col,
    > [class*="col-"] {
      padding-right: $size;
      padding-left: $size;
    }
  }
}
.gutter-4 {
  @include gutter-width(4px);
}

// .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
//     border: 1px solid #efefef;
//     margin-top: 0;
//     min-height: 100px;
// }
#custom-text-editor {
  .rdw-editor-toolbar {
    margin: 0;
    padding: 10px;
  }
  .demo-editor.rdw-editor-main {
    background: #fdfdfd;
    border: 1px solid #efefef;
    padding: 5px;
    margin-bottom: 15px;
  }
  .DraftEditor-root {
    height: 70px;
  }
  .rdw-editor-wrapper + textarea {
    width: 100%;
    margin-top: 15px;
    height: 80px;
    padding: 10px;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
}
.previewComponent form,
.previewComponent input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: visible;
  border-radius: 50%;
}

.previewComponent {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: visible;
  background: #d8d8d8;
  form,
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 50%;
  }
  form {
    input {
      cursor: pointer;
      opacity: 0;
    }
    button.submitButton {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-weight: 600;
      position: relative;
      z-index: 2;
    }
  }
}
// Profile Popup
@mixin colWidth($width) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $width;
  flex: 0 0 $width;
  max-width: $width;
  width: $width;
}

@mixin tableWidth($width) {
  min-width: $width !important;
  max-width: $width !important;
  width: $width !important;
}

.profile-details {
  margin-bottom: 1.5rem;
  label {
    font-size: 13px;
    line-height: 14px;
    color: #454545;
    margin: 0;
    position: relative;
    &:not(.email-label) {
      @include colWidth(75px);
    }
    &.email-label {
      word-break: break-all;
    }
    .birthdate {
      position: absolute;
      left: 0;
      top: 100%;
      margin-top: 5px;
      font-weight: bold;
      line-height: 8px;
      font-size: 8px;
    }
  }
  .form-control {
    height: 38px;
    font-size: 1rem;
  }
}
.modal-dialog.profile-popup {
  @media (min-width: 768px) {
    max-width: 650px;
  }
  .btn-primary {
    font-size: 15px;
    padding: 8px 40px;
  }
}

// Profile Logout
.profile-dropdown {
  .dropdown-menu {
    background: #fff;
    border: 0;
    box-shadow: 0 0 5px -2px #454545;
    margin-top: 20px;
    border-radius: 0;
    .dropdown-item {
      border: 0;
      font-size: 16px;
      font-weight: 600;
      i {
        font-size: 20px;
        color: #8c8c8c;
        margin-right: 5px;
      }
      &:hover,
      &.active {
        background: #0197cc;
        color: #fff;
        i {
          color: #fff;
        }
      }
    }
  }
}

.progress-bar {
  &.CA-2f2f2f {
    background-color: #2f2f2f;
  }
  &.CA-0197CC {
    background-color: #0197cc;
  }
  &.CA-0086C0 {
    background-color: #0086c0;
  }
  &.CA-107f4c {
    background-color: #107f4c;
  }
  &.CA-c5c5c5 {
    background-color: #c5c5c5;
  }
  &.CA-24ce6a {
    background-color: #24ce6a;
  }
  &.CA-fbac47 {
    background-color: #fbac47;
  }
  &.CA-A25DDC {
    background-color: #a25ddc;
  }
  &.CA-df445f {
    background-color: #df445f;
  }
  &.CA-feca2e {
    background-color: #feca2e;
  }
  &.CA-456945 {
    background-color: #456945;
  }
  &.CA-6f4b93 {
    background-color: #6f4b93;
  }
  &.CA-b7274b {
    background-color: #b7274b;
  }
  &.CA-e77b57 {
    background-color: #e77b57;
  }
  &.CA-934b6f {
    background-color: #934b6f;
  }
  &.CA-6f6f6f {
    background-color: #6f6f6f;
  }
  &.CA-218d8d {
    background-color: #218d8d;
  }
  &.CA-27b74b {
    background-color: #27b74b;
  }
  &.CA-bd51bd {
    background-color: #bd51bd;
  }
  &.CA-2dbce1 {
    background-color: #2dbce1;
  }
  &.CA-red {
    background-color: #df445f;
  }
  &.CA-orange {
    background-color: #fbac47;
  }
  &.CA-blue {
    background-color: #0197cc;
  }
  &.CA-green {
    background-color: #0b6623;
  }
  &.Gradient-red {
    background: linear-gradient(
      90deg,
      #ff6060 0%,
      #fdd 25.06%,
      #fff 50.64%,
      #fdd 75.92%,
      #ff6060 100%
    );
    // background: rgb(255, 96, 96);
    // background: linear-gradient(
    //   270deg,
    //   rgba(255, 96, 96, 1) 0%,
    //   rgba(255, 221, 221, 1) 35%,
    //   rgba(255, 221, 221, 1) 50%,
    //   rgba(255, 221, 221, 1) 65%,
    //   rgba(255, 96, 96, 1) 100%
    // );
    // background: rgb(234, 34, 37);
    // background: linear-gradient(
    //   90deg,
    //   rgba(234, 34, 37, 1) 0%,
    //   rgba(252, 221, 34, 1) 25%,
    //   rgba(252, 221, 34, 1) 35%,
    //   rgba(13, 160, 69, 1) 50%,
    //   rgba(252, 221, 34, 1) 65%,
    //   rgba(252, 221, 34, 1) 75%,
    //   rgba(234, 34, 37, 1) 100%
    // );
  }
}

.user-sm-img {
  width: 60px;
  height: 60px;
  margin-left: 10px;
}

/* New css */
// .profilePicture{
//     img{
//         width: 150px;
//         height: 150px;
//     }
// }

@media (max-width: 992px) {
  /* Table UI */
  // .goalListData{
  //     thead{
  //         display: none;
  //     }
  //     td{
  //         display: block;
  //         width: 100% !important;
  //         position: relative;
  //         &::before{
  //             position: absolute;
  //             left: 10px;
  //         }
  //         &:last-child{
  //             margin-bottom: 10px;
  //         }
  //     }
  //     .noGoalDate{
  //         height: 36px;
  //     }
  //     td.tBorder{
  //         display: none;
  //     }
  //     td.tGoalName{
  //         padding-right: 5px;
  //         p{
  //             margin-left: 50% !important;
  //             width: auto !important;
  //            }
  //         &::before {
  //             content: "Goal";
  //         }
  //     }
  //     td.tGoalDate{
  //         padding-right: 5px;
  //        div{
  //         margin-left: 50% !important;
  //        }
  //         &::before {
  //             content: "Due date";
  //         }
  //     }
  //     td.tProgress{
  //         padding-right: 5px;
  //         &::before {
  //             content: "Progress";
  //         }
  //         > div{
  //           width: 50% !important;
  //             margin-left: 50% !important;
  //            }
  //     }
  // }

  .customSideBar {
    width: 100% !important;
    flex: 100% !important;
  }
  .hideTd {
    display: none !important;
  }
  .dashboardContent {
    padding-left: 15px !important;
  }
}
.profile-heading {
  width: 100%;
  text-align: left;
  font-weight: 700;
}

.editor-desc {
  width: 100%;
  text-align: left;
  display: flex;
  flex-flow: column;
  height: 100%;
  .form-control {
    height: 100%;
  }
}

// OTS Timestamp
.ots-text-chat .ots-messages-holder .ots-message-item .ots-item-timestamp {
  white-space: normal !important;
}

.ots-text-chat .ots-messages-holder .ots-messages-alert {
  min-height: auto !important;
}

.alert {
  word-break: break-all !important;
}

.upcoming-sessions {
  li {
    a,
    a:hover,
    .btn-plain,
    .btn-plain:hover {
      background-color: #4dbd74;
      color: #fff !important;
      padding: 2px 12px !important;
    }
  }
}

.top-bar-fixed {
  background: #8f9295;
}
.text-center.top-bar-meet p span:first-child {
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
}
.text-center.top-bar-meet p span:nth-child(2) {
  padding: 10px;
  font-size: 16px;
}
.text-center.top-bar-meet p span:nth-child(3) {
  padding: 10px;
  font-size: 14px;
}
.top-bar-meet img {
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 30px;
}
.top-bar-fixed img {
  width: 20px;
  margin-right: 10px;
}
.top-bar-fixed {
  display: none;
}
.top-bar .top-bar-fixed {
  display: block;
}
.top-bar.gray-box > p,
.top-bar.gray-box > h5 {
  padding: 0rem 1rem;
}
.top-bar.gray-box {
  padding: 0px;
}
.notes_session p {
  //border-bottom: 1px solid #ccc;
  padding: 5px 6px 3px 6px;
  font-size: 14px;
}
.notes_session .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  content: "\f0c6" !important;
  font-family: FontAwesome;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  -webkit-text-stroke-color: #d00a4f;
  color: #d00a4f !important;
  -webkit-text-stroke-width: medium;

  background-color: transparent !important;
  border-left: transparent !important;
  border-radius: 0 0.25rem 0.25rem 0;
}
.notes_session .custom-file label {
  background-color: #f1efef;
  font-size: 12px;
  padding-left: 24px;
}
.notes_session .custom-file {
  margin-top: 40px;
}
.top-bar-meet {
  padding: 20px;
  background-color: #8f9295;
  color: #fff;
}
@media (max-width: 1353px) {
  /* Table UI */
  // .goalListData thead {
  //   display: none; }
  // .goalListData td {
  //   display: block;
  //   width: 100% !important;
  //   position: relative; }
  //   .goalListData td::before {
  //     position: absolute;
  //     left: 10px; }
  //   .goalListData td:last-child {
  //     margin-bottom: 10px; }
  // .goalListData .noGoalDate {
  //   height: 36px; }
  // .goalListData td.tBorder {
  //   display: none; }
  // .goalListData td.tGoalName {
  //   padding-right: 5px; }
  //   .goalListData td.tGoalName p {
  //     margin-left: 50% !important;
  //     width: auto !important; }
  //   .goalListData td.tGoalName::before {
  //     content: "Goal"; }
  // .goalListData td.tGoalDate {
  //   padding-right: 5px; }
  //   .goalListData td.tGoalDate div {
  //     margin-left: 50% !important; }
  //   .goalListData td.tGoalDate::before {
  //     content: "Due date"; }
  // .goalListData td.tProgress {
  //   padding-right: 5px; }
  //   .goalListData td.tProgress::before {
  //     content: "Progress"; }
  //   .goalListData td.tProgress > div {
  //     width: 50% !important;
  //     margin-left: 50% !important; }
  .customSideBar {
    width: 100% !important;
    flex: 100% !important;
  }
  .hideTd {
    display: none !important;
  }
  .dashboardContent {
    padding-left: 15px !important;
  }
}

.clickawayListener {
  border: none;
}

.clickawayListener:focus {
  border: none;
  outline: none;
}

textarea.form-control {
  height: 100px;
}

.growtth_img a {
  width: 100%;
  margin: 0;
}
.growtth_img a {
  text-align: center;
}
.no-gutters {
  .navbar-brand {
    height: 300px !important;
  }
}
.cursor {
  cursor: pointer;
}
.empty {
  text-align: center;
  width: 100%;
}

.goalList button.btn.btn-primary {
  font-size: 16px;
  width: 100%;
  z-index: 999999;
}
img.show-on-micromentor {
  width: 75px;
}

// Video sharing
#annotationToolbarContainer {
  display: none;
}
span.text-primary.cursor:hover {
  text-decoration: underline;
}

.goal_box .react-datepicker-popper {
  z-index: 2;
}

.tab-content .wrapperClassName.rdw-editor-wrapper {
  width: 100%;
  overflow: hidden;
}

.data-style {
  text-align: left;
  min-height: 50px;
  margin: 0px !important;
  width: 100%;
  overflow: hidden;
  max-height: 155px;
  overflow-y: auto;
}

.editor-style {
  margin-top: 42px;
  width: 100%;
  display: block;
}

.selected-goal-action-list {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: 0 -5px;
  .action-list {
    // -ms-flex: 0 0 33.33333%;
    // flex: 0 0 50%;
    // max-width: 50%;
    // padding: 0 5px;
    margin-right: 7px;
  }
  button {
    width: 100%;
    // height: 100%;
  }
}
.btn.btn-primary:not(.btn-sm) {
  font-size: 16px;
  padding: 10px 20px;
}

.editorClassName .DraftEditor-editorContainer {
  z-index: 0;
}
.gp-label {
  font-size: 21px;
}
small.text-half {
  font-size: 50%;
}
.alertMessage {
  word-break: normal !important;
}

.card-body-border-none {
  border: none;
  box-shadow: none;
}
.editorData {
  width: 100%;
  text-align: left;
}
.display-interest {
  display: block;
}
.confettiActive > canvas {
  position: absolute;
  top: 0;
  z-index: 99;
  display: inline-block;
}
.confettiInActive > canvas {
  display: none;
}

.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  overflow: auto;
}
.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;

  display: flex;
  border: none;
  font-size: 15px;

  align-items: center;

  justify-content: center;
  background-color: #f1f1f1;
  outline: none !important;
  outline-offset: -10px;
  margin: 10px 0;
  padding: 9px 0;
}
.min-height-card .table_view {
  // max-height: 105px;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}
.no_meeting {
  text-align: center;
  margin-top: 100px;
}

/*----MS Edge Browser CSS Start----*/
@supports (-ms-ime-align: auto) {
  .leftSideVideo .video-container.small .OT_publisher video {
    border-radius: 0 !important;
  }
  .OT_publisher .OT_video-element,
  .OT_subscriber .OT_video-element {
    width: auto !important;
  }
}
/*----MS Edge Browser CSS End----*/
.meeting_active {
  border-bottom: 1px solid #0197cc;
  padding-bottom: 10px;
}
.react-datepicker-popper {
  z-index: 2;
}
@media all and (-ms-high-contrast: none) {
  .rdw-image-wrapper {
    display: none !important;
  } /* IE10 */
  *::-ms-backdrop,
  .rdw-image-wrapper {
    display: none !important;
  } /* IE11 */
  #cameraSubscriberContainer
    > .OT_subscriber
    > .OT_widget-container
    > .OT_video-element,
  #cameraSubscriberContainer .OT_subscriber,
  #cameraSubscriberContainer .OT_subscriber .OT_widget-container,
  #cameraSubscriberContainer .OT_subscriber .OT_video-element {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
  }
}
#cameraSubscriberContainer
  > .OT_publisher
  > .OT_widget-container
  > .OT_video-element {
  border-radius: 50% !important;
}
.App-video-container .video-container.small video.OT_video-element {
  border-radius: 50%;
}
// #cameraSubscriberContainer>.OT_subscriber>.OT_widget-container>.OT_video-element{
//     border-radius: 0px !important;
//     -webkit-border-radius:0px !important;
// }
// #cameraSubscriberContainer>.OT_subscriber>.OT_widget-container>.OT_video-element , #cameraSubscriberContainer .OT_subscriber , #cameraSubscriberContainer .OT_subscriber .OT_widget-container ,#cameraSubscriberContainer .OT_subscriber .OT_video-element {
//     border-radius: 0px !important;
//     -webkit-border-radius:0px !important;
// }
.goalAction .goalList {
  height: 187px;
  overflow-y: auto;
  // border: 1px solid #cccccc;
  padding: 8px;
  position: absolute;
}

.profileImageCircle {
  height: 30px;
  width: 30px;
  background-color: #fdfdfd;
  /* border-radius: 50%; */
  /* border: 1px solid #0000001f; */
  position: absolute;
  right: 0px;
  z-index: 99;
  bottom: 10px;
  background: transparent;
  overflow: hidden;
}
.meetingcalendrview {
  margin-top: -4px;
}
.imageIn {
  display: inline-block;
  width: 18px !important;
  padding: 18px 0 0px 1px;
  height: 18px !important;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  background-size: contain !important;
  background: url(../assets/img/icons/edit-light.svg) no-repeat;
  background-position: center;
  cursor: pointer;
  text-indent: 999px;
  text-indent: 999px;
  left: 5px !important;
  top: 4px !important;
  border-radius: 0px !important;
}
.imageIn:hover {
  background: url(../assets/img/icons/edit-lighthover.svg) center center
    no-repeat transparent;
}

.editIcon {
  width: 18px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 18px;
}

.border-danger {
  border-color: red;
}
.text-danger {
  color: red;
}
.text-darkGreen {
  color: #456945;
}

.modalclosebtn {
  position: absolute;
  top: 35px;
  right: 20px;
  cursor: pointer;
}
.btn-secondary {
  color: #1c9fe0;
  background-color: #ffffff;
  border-color: #1c9fe0;
  padding: 11px 20px;
}
.btn-secondary:hover,
.btn-secondary:focus {
  color: #1c9fe0;
  background-color: #e5f7fc;
  border-color: #1c9fe0;
  box-shadow: 0 0 0 0.2rem #e5f7fc;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #23282c;
  background-color: #ffffff;
  border-color: #c8ced3fa;
  opacity: 0.3;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #1c96c1;
  background-color: #9bd5e9;
  border-color: #0097cc;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(175, 181, 186, 0.5);
}
.btn-danger {
  color: #f86c6b;
  background-color: #ffffff;
  border-color: #f86c6b;
  padding: 11px 20px;
}
.btn-danger:hover,
.btn-danger:focus {
  color: #f86c6b;
  background-color: #fce5e5;
  border-color: #f86c6b;
  box-shadow: 0 0 0 0.2rem #fce5e5;
}
.lengthcardtext:last-child {
  border-bottom: none !important;
}
.lengthcardtext {
  margin: 0px -5px;
}
.goalaction_datepicker br {
  display: none;
}
.goalaction_datepicker input {
  border: 2px solid #8080808f;
  padding: 2px 5px;
}
.goalaction_datepicker label {
  margin-right: 10px;
  vertical-align: middle;
}
.goalaction_header h2 {
  color: #000000;
}

.lengthcardtext textarea {
  height: 202px;
  border-radius: 0;
  border-color: #c5c5c5;
}
@media (max-width: 767px) {
  .goalaction_header {
    display: block !important;
  }
  .Gethelpmain {
    padding: 50px 50px 0px 50px !important;
  }
}
.hoverdisplayblock {
  // display: none;
  opacity: 0;
}
.selectgoalhover button:hover .hoverdisplaynone {
  display: none;
  transition: 0.2s;
}
.selectgoalhover button:hover .hoverdisplayblock {
  display: inline-block;
  transition: 0.2s;
  opacity: 1;
}
.growthplans_head {
  padding: 12.3px 10px;
  background-color: white;
  color: #000000;
  border-left: 1px solid #0097cc;
  vertical-align: top;
}
.headerhelp {
  padding: 0;
  /* line-height: 34px; */
  /* color: #948a8a; */
  width: 25px;
  /* height: 34px; */
}
.helpimagebg {
  width: 24px;
  height: 24px;
  background-image: url(../assets/img/icons/question-square-light.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.helpimagebg:hover {
  background-image: url(../assets/img/icons/question-square-lightHoverState.svg);
}
.calenderimageheader {
  width: 25px;
  padding: 0;
}
.micromendorimagebg {
  width: 26px;
  height: 27px;
  background-image: url(../assets/img/icons/OnUpMicroMentorIconAllGrey.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-top: 1px;
}
.micromendorimagebg:hover {
  background-image: url(../assets/img/icons/OnUpMicroMentorIconHover.svg);
}
.canlenderimagebg {
  width: 24px;
  height: 24px;
  background-image: url(../assets/img/icons/calendar-light.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.canlenderimagebg:hover {
  background-image: url(../assets/img/icons/calendar-lightHover.svg);
}
.trashiconbg {
  width: 14px;
  height: 17px;
  background-image: url(../assets/img/icons/trash-light.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.trashiconbg:hover {
  background-image: url(../assets/img/icons/trash-lightHover.svg);
}
.viewiconbg {
  width: 16px;
  height: 16px;
  background-image: url(../assets/img/icons/eye-light.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.viewiconbg:hover {
  background-image: url(../assets/img/icons/eye-lightHover.svg);
}
.growthviewicon {
  background-color: transparent;
  border: none;
  padding: 4px 2px;
  margin: 0px !important;
  width: 24px !important;
}
.editiconbg {
  width: 18px;
  height: 16px;
  background-image: url(../assets/img/icons/edit-light.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.editiconbg:hover {
  background-image: url(../assets/img/icons/edit-lighthover.svg);
}
.calnederimagehov {
  width: 16px;
  height: 15px;
  background-image: url(../assets/img/icons/calendar-light.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 4px;
}
.calnederimagehov:hover {
  background-image: url(../assets/img/icons/calendar-lightHover.svg);
}
.meetingcalendericonset:hover .calnederimagehov {
  background-image: url(../assets/img/icons/calendar-lightHover.svg);
}
.meetingcalendericonset:hover .arrowbtnset {
  background-image: url(../assets/img/icons/caret-down-solidHover.svg);
}
.arrowbtnset {
  width: 22px;
  height: 16px;
  background-image: url(../assets/img/icons/caret-down-solid.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 4px;
}
.arrowbtnset:hover {
  background-image: url(../assets/img/icons/caret-down-solidHover.svg);
}
.ma_top {
  margin-top: 25px;
}
.calnederlistimagehov {
  width: 16px;
  height: 16px;
  background-image: url(../assets/img/icons/list-light.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 4px;
  margin-top: -3px;
}
.calnederlistimagehov:hover {
  background-image: url(../assets/img/icons/list-lightHover.svg);
}

.calendarheader {
  width: 34px;
  height: 34px;
  line-height: 30px;
  border: 1px solid black;
  border-radius: 20px;
}
.activeBorder {
  border-style: solid;
  border-color: #03a9f4;
}
.dottedborder {
  border: 2px dashed;
}
.react-switch-bg {
  border-radius: 13.5px !important;
}
.question {
  height: 30px;
  background-color: #dddddd;
  padding-left: 10px;
  display: table;
  width: 100%;
}

.question > span {
  display: table-cell;
  padding-left: 10px;
  vertical-align: middle;
}

.errorscreen {
  color: #e52f74;
  background-color: #feccd8;
  font-weight: bold;
  padding: 0px 4px;
}
.errorscreen small {
  font-weight: 600;
}
.growthdelete {
  background-color: transparent;
  border: none;
  padding: 10px 0px;
  // margin: 0px !important;
}
.headerprofile-image {
  width: 60px;
  height: 60px;
}

// .selected-goal-action-list .action-list {
//     -ms-flex: 0 0 33.33333%;
//     flex: 0 0 50%;

//     padding: 0 5px;
//  }

.completeProfile {
  span,
  a {
    color: #139bcc !important;
    text-decoration: underline !important;
    text-decoration-color: #119bcc;
  }
}
.blueColorh5 {
  color: #139bcc !important;
}
// .colorpickdes:after {
//     height: 24px;
//     width: 34px;
//     position: absolute;
//     top: -6px;
//     content: "";
//     right: 0;
// }
.activityactiondetails {
  min-height: 200px;
  overflow: auto;
  height: 200px;
  p {
    color: #757474;
    line-height: 28px;
  }
}
.btn-gray:hover .texthovercan:before {
  content: "\F00D";
  padding: 2px 4px;
  border: 1px solid;
  border-radius: 40px;
}
.collpsedays.show .nav {
  display: block;
  position: absolute;
  right: 30px;
  top: -10px;
  z-index: 99;
  background-color: white;
}
.collpsedays .nav {
  display: none;
}
.btn-gray i {
  color: #21a8d8;
}
.list_calender .dropdown-menu.show {
  left: auto !important;
  right: -15px !important;
}
.list_calender .dropdown-menu.show:after {
  content: "";
  position: absolute;
  top: -10px;
  right: 16px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}
.goalaction_datepicker .react-datepicker-wrapper {
  vertical-align: middle;
}
.selectgoalhover button .hoverdisplayblock {
  padding: 2px 4px;
  border: 1px solid;
  border-radius: 30px;
}
.action-list {
  button {
    .hoverdisplayblock {
      border: 1px solid;
      padding: 2px 4px;
      border-radius: 30px;
      margin-top: 0 !important;
      margin-left: 5px;
    }
    .hoverdisplaynone {
      padding: 2px 4px;
      margin: 0 !important;
      border: 1px solid transparent;
    }
  }
}
.list_calender button.dropdown-item {
  padding: 0;
}
.list_calender button.dropdown-item span {
  padding: 0.8rem 1rem;
}
.newmeeting_card {
  border: none;
  border-radius: 0px;
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: none;
  }
}
.reshedule {
  border-width: 2px !important;
}
.CancelMeeting {
  border: 2px solid;
  padding: 6px 12px !important;
}
.reshedule {
  border: 2px solid #0097cc;
}
.meetint_about {
  border: 2px solid #80808061 !important;
}

// .addgoalactionselect .col-md-6 {
//     -ms-flex: 0 0 50%;
//     flex: 0 0 100%;
//     max-width: 100%;
// }
// .addgoalactionselect .row {
//     flex-direction: column-reverse;
// }
.Gethelpmain {
  padding: 100px 300px 0px 300px;
}
.list_calender {
  height: 16px;
}
.list_calender a {
  line-height: 1;
}
.lengthcardtext textarea:focus {
  outline: none;
  box-shadow: none;
  border-color: #c5c5c5;
}
.textarea_height {
  height: 220px;
  width: 100%;
  background-color: white;
}
.feedback-wrapper .feedback-body .default_description {
  width: 100% !important;
}
input.text {
  width: 100%;
}
// for Edge
@supports (-ms-ime-align: auto) {
  .calnederimagehov {
    width: 22px;
    height: 20px;
  }
  .meetingcalendericonset {
    width: 40px !important;
  }
  .meetingcalendericonset .arrowbtnset {
    width: 10px !important;
  }
}
// for ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  // .addgoalactionselect .row {
  //     flex-direction: row !important;
  //     -ms-flex-direction: row !important;
  // }
  // .canlenderimagebg{
  //     height: 26px !important;
  // }
}
// @media all and (-ms-high-contrast: none),(-ms-high-contrast: active) and (min-width: 1252px) and (max-width: 1325px){
//     .addgoalactionselect .row {
//         flex-direction: initial;
//     }
// }
.meetingcalendericonset {
  display: inline-block;
  width: 33px;
  .calnederimagehov {
    float: left;
  }
  .arrowbtnset {
    width: 9px;
    float: right;
    margin-top: 2px;
  }
}
.montlyactivity {
  @include gutter-width(5px);
  span {
    height: 70px;
    background-color: #f0f0f0;
    font-size: 31px;
    color: #0097cc;
    text-align: center;
    @include center-section();
  }
  a {
    font-size: 12px;
    font-weight: 600;
    color: black;
    text-align: center;
    display: inline-block;
    text-decoration: none;
  }
}
.growthhtracts a {
  padding: 8px 0px;
  color: #139bcc !important;
  text-decoration: underline !important;
  font-size: 16px;
}
.form-control.restfrpwd hr {
  display: none;
}
.form-control.restfrpwd {
  width: 100% !important;
  background-color: #6e7f8d !important;
  margin-bottom: 5px !important;
}
.resetsubmit > button {
  background-color: transparent !important;
  color: white;
  font-weight: 600 !important;
}
.resetsubmit {
  background-color: transparent !important;
  margin-top: 10px;
  border-radius: 7px !important;
}
.form-control.restfrpwd > div {
  display: block;
  position: relative !important;
  /* min-height: 20px; */
  // margin-top: 20px;
}
.meetlistedit {
  // width: 40px;
  // height: 40px;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}
.center-height {
  height: calc(100vh - 85px);
}

@mixin placeholder($color) {
  &:-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
}

.tabulator {
  background: #efedee;
  margin-bottom: 15px;
  border: 0;
  z-index: 0;
  height: 70vh;

  .tabulator-header {
    // background: #9e9e9e;
    background: map-get($colors, PrimaryBlue) !important;
    font-size: 14px;
    font-weight: 500;
    color: #fff !important;
    border: 0;
    z-index: 1;
    min-height: 30px;

    .tabulator-col,
    .tabulator-col-row-handle {
      border: 0;
    }
    .tabulator-col {
      background: transparent !important;

      .tabulator-header-filter {
        input {
          background: transparent;
          border: 0;
          border-bottom: 1px solid #dedede;
          height: 30px;
          color: #efedee;
          font-size: 12px;
          @include placeholder(#efedee);
        }
      }
    }
  }
  .tabulator-tableholder {
    background-color: #fff;
    .tabulator-table {
      background: transparent;

      .tabulator-row {
        background: transparent;
        border-bottom: 1px solid #d9d7d8;

        &:nth-child(even) {
          background-color: #f9f9f9;
        }
        &:hover {
          background: #f4f4f4 !important;
        }
        .tabulator-cell {
          border: 0;
          padding: 10px 20px;
          color: #727272;
          font-weight: 400;

          button:not(.actionbutton),
          .editButton {
            margin: 0 5px;
            position: relative;
            z-index: 0;
            img {
              max-height: 100%;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
  .tabulator-table:empty {
    min-height: 98% !important;
    min-width: 100% !important;
    visibility: visible !important;
    z-index: 1;
    background: transparent;

    &:empty:before {
      content: "No Records Found";
      position: absolute;
      color: #333;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .tabulator-headers .tabulator-col-title > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tabulator-footer {
    padding: 10px 10px;
    border-top: 1px solid #ccc;
    background-color: #dddbdc;
    position: relative;
    z-index: 1;

    .tabulator-page {
      display: inline-block;
      text-align: center;
      background: transparent;
      box-shadow: none;
    }
    .tabulator-pages {
      .tabulator-page {
        background: #e6e6e6;
        color: #181515;
        padding: 0px 11px;

        &.active {
          background: #0197cc;
          color: #fff;
        }
      }
    }
  }
}
.select-users {
  color: #f30101bf;
  margin-top: -10px;
}
.height-70 {
  height: 70px;
  overflow-y: auto;
}
.picker_container {
  margin: 0 -6px 20px !important;
}

.meetinglistnew .tabulator {
  height: 70vh;
}
.primaryhiver:hover {
  background-color: #0197cc !important;
  color: white !important;
}
.restfrpwd input {
  color: white !important;
}
.primaryhiver {
  width: auto !important;
}
.calenderjoinnowbtn {
  background-color: #4dbd74;
  color: #fff !important;
  padding: 2px 12px !important;
  font-size: 12px;
  line-height: 13px;
}
.sharescreenhide {
  z-index: -999 !important;
}
.change-password {
  .form-control {
    label {
      color: #fff !important;
    }
  }
  button {
    background: #0197cc !important;
    border-radius: 4px !important;
    background-color: #0197cc !important;
    border-color: #0197cc !important;
    color: #fff !important;
    -webkit-transition-duration: 0.4s !important;
    -o-transition-duration: 0.4s !important;
    transition-duration: 0.4s !important;
    div {
      div {
        padding: 10px 20px !important;
        height: auto !important;
        line-height: 1;
        border-radius: 0 !important;
      }
    }
  }
}
.growthhtracts {
  h5 {
    margin-bottom: 0px;
  }
}
.activityactiondetails::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.activityactiondetails::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.activityactiondetails::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

.guidedtours {
  color: #139bcc !important;
  text-decoration: underline !important;
  text-decoration-color: #119bcc;
  font-size: 16px;
}
.overlay {
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.25);
    z-index: 1;
  }
}
.popover {
  z-index: 90 !important;
}
.goalpopover{
  .popover{
    z-index: 1051 !important;
  }
}
.joinnowdisabled {
  color: #fff;
  background-color: #d3cbcb;
  border-color: #d3cbcb;
  pointer-events: none;
}
.canceldisabled {
  color: #fff;
  background-color: #d3cbcb;
  border-color: #d3cbcb;
  pointer-events: none;
  border: 2px solid;
  padding: 6px 12px !important;
}
.pointer-events-none {
  pointer-events: none;
}
.customsub {
  font-size: 20px;
}
.progress-tsm {
  height: 4px !important;
}

.fixed-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  left: -37px;
}

.slidingRight,
.slidingLeft {
  position: fixed;
  left: 40px;
  top: 50%;
  transform: translate(-700px, -50%);
  width: 310px;
  opacity: 0;
  transition: 0.5s;
  max-width: 700px;
  width: 100%;
  /* display: none; */
  /* -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;  */
  .card {
    margin: 0;
  }
}
.slidingRight {
  opacity: 1;
  transform: translate(0, -50%);
  + .fixed-button i {
    transform: rotate(180deg);
  }
}

.text-placeholder {
  opacity: 0.4;
  color: #5c6873;
}

.btn.resendEmailBtn.btn-sm {
  line-height: 1;
  font-size: 11px;
}
.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5;
}

.headerintialCaps {
  font-size: 125%;
}

.card-group .CP-Dashboard-card {
  .card {
    height: 255px;
  }
}

.labelbgColor {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
}
.textEditorNotes {
  .rdw-editor-main {
    // height: 250px;
    height: 180px;
  }
  border-radius: 5px;
}
.tabulator {
  .tabulator-tableHolder .tabulator-table .tabulator-row.toggleTabulator {
    background: transparent !important;
    border: 0;
    &:hover {
      background: transparent !important;
    }
  }
}

.meetnowVideoContainer {
  max-height: 480px;
  -ms-flex: 0 0 420px;
  flex: 0 0 420px;
  max-width: 420px;
  .App-control-container.videoControls {
    width: 100% !important;
  }
  .leftSideVideo {
    width: 100% !important;
    height: 320px !important;
  }
  .chatSection {
    display: none;
  }
  .leftSideVideo {
    padding: 75px 1.2em 0 !important;
    .video-container.small {
      width: 50px !important;
      height: 50px !important;
    }
  }
  .gray-box {
    padding-top: 8px !important;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
  }
  &.loader {
    .gray-box {
      position: relative;
      &:before {
        content: "Loading...";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.5);
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 25px;
      }
    }
  }
}

.myTeamPlans {
  .activationTabs {
    border: 1px solid map-get($colors, PrimaryBlue);
    background: #fff;
    span {
      color: map-get($colors, PrimaryBlue);
      padding: 8px 15px;
      color: map-get($colors, PrimaryBlue);
      font-size: 13px;
      display: inline-block;
      cursor: pointer;
      &.active {
        background: map-get($colors, PrimaryBlue);
        color: #fff;
      }
    }
  }
  // .myTeamPlanName {
  //     width: 400px;
  // }
  span.ml-0.gp-label {
    display: none;
  }
  // .myTeamPlanName {
  //     .gp-label {
  //         font-size: 14px;
  //     }
  // }
  // .myTeamPlanCheckbox {
  //     font-size: 12px;
  // }
  .myTeamPlanTable {
    tr {
      td {
        font-size: 14px;
        color: #979797;
        vertical-align: middle;
        border: 0;
        padding: 5px;
        &:nth-child(1) {
          width: 100px;
          max-width: 100px;
        }
        &:nth-child(2) {
          width: 180px;
          max-width: 180px;
        }
        &:nth-child(3) {
          width: 70px;
          max-width: 70px;
          text-align: center;
        }
        &:nth-child(5) {
          width: 60px;
          max-width: 60px;
        }
        &:nth-child(6) {
          width: 25px;
          max-width: 25px;
          .trashiconbg input {
            opacity: 0;
            cursor: pointer;
          }
        }
        &.progressdata {
          min-width: 160px;
          width: 160px;
        }
      }
    }
  }
  a.navbar-brand {
    width: 150px;
    margin: 0 auto 20px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
    }
  }
}

.sessionScrollableSection {
  max-height: 300px;
  overflow: auto;
}
.sidebarLogoSection {
  margin-bottom: 35px;
  padding-bottom: 15px;
  position: relative;
  &:after {
    content: "";
    bottom: 0;
    width: 100px;
    left: 0;
    right: 0;
    margin: auto;
    background: #ccc;
    height: 2px;
    position: absolute;
  }
  .cursor {
    color: map-get($colors, PrimaryBlue);
    text-decoration: underline;
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 600;
  }
  p {
    line-height: 1;
    margin: 0;
    small {
      font-size: 68%;
    }
  }
}

.dashboard_calendar .calendar i {
  padding: 15px 20px;
  background-color: #0197cc;
  color: #fff;
  width: 50px;
  border-radius: 5px;
}
.dashboard_calendar span#today_date {
  font-size: 18px;
  margin: 10px;
}
.sessionScrollableSection:empty {
  display: none;
}

.fullpage_loader {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  font-size: 25px;
  color: #333;
  &.show {
    display: flex;
  }
}

.disabledDiv:before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // z-index: 9;
}
.disabledAtion:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.gp-details-container {
  .MuiSlider-root.Mui-disabled {
    cursor: default;
    color: orange;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #fff;
    opacity: 1;
  }
}
.collapseHeaderContent {
  position: relative;
  &::before {
    content: "\F0DA";
    font-family: "FontAwesome";
    position: absolute;
    font-size: 30px;
    left: -15px;
    top: -10px;
    color: #0398cc;
  }
  &.collapseOpen {
    &::before {
      content: "\F0D7";
    }
  }
}

button {
  &.videoOverlay {
    background: transparent;
    position: relative;
    border: 0;
    width: 33.333333%;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    iframe {
      width: 100%;
    }
  }
}
iframe .vp-controls-wrapper {
  display: none !important;
}
.sidebarthoughteditor {
  .rdw-editor-toolbar {
    display: none;
  }
}

.inviteContributeSlide {
  position: absolute;
  left: 0;
  width: 0;
  // height: 60%;
  min-height: 616px;
  max-height: 616px;
  background: #fff;
  bottom: 20%;
  border-radius: 5px;
  transition: 0.5s;
  z-index: 999999;
  display: flex;
  flex-flow: column;
  > div {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: auto;
  }
  &.active {
    width: 60%;
    border: 1px solid #0197cc;
    .btn-primary {
      right: -210px;
    }
  }
  .btn-primary {
    position: absolute;
    top: 120px;
    transform: rotate(90deg);
    right: -180px;
    white-space: nowrap;
    transform-origin: left;
  }
}
.communityAddStyle {
  background-color: #0197cc;
  color: white;
  border-radius: 3px;
  border: none;
  padding: 5px 10px;
}
.communityCancelStyle {
  padding: 4px 8px;
  background-color: white;
  border: 1px solid #0197cc;
  border-radius: 3px;
  color: #0197cc;
}
.autoComplete > div,
.autoComplete input {
  width: 100%;
}
.autoComplete div > div {
  z-index: 999;
}

.cardBorderContent {
  border: 2px solid;
  margin-bottom: 30px;
  .cardBorderContentHeader {
    padding: 5px 15px;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
  }
  &.cardBorderContentRed {
    border-color: #df445f;
    .cardBorderContentHeader {
      background: #df445f;
    }
  }
  &.cardBorderContentprimary {
    border-color: #2dbce1;
    .cardBorderContentHeader {
      background: #2dbce1;
    }
  }
  &.cardBorderContentGreen {
    border-color: #4eb968;
    .cardBorderContentHeader {
      background: #4eb968;
    }
  }
  &.cardBorderContentGrey {
    border-color: #8f9295;
    .cardBorderContentHeader {
      background: #8f9295;
    }
  }
  #custom-text-editor,
  .demo-editor {
    margin: 0 !important;
  }
}
.calendarFields {
  width: 16.666666%;
  .radioWrap {
    position: relative;
    margin: 1px;
    input {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: cell;
      &:checked {
        + span {
          background-color: #34b0dc;
        }
      }
    }
    span {
      display: block;
      background-color: #ddd;
      cursor: cell;
      height: 28px;
    }
  }
  label {
    height: 28px;
    margin: 1px;
    text-align: center;
  }
}

.purpleBox {
  background: #a25ddc;
  border-radius: 5px;
  .btn {
    width: auto;
    padding: 10px;
    line-height: 1;
    color: #47494a;
    font-size: 14px;
    min-width: 115px;
    &.btn-success {
      background-color: #24ce6a;
      color: #fff;
    }
  }
  p {
    font-size: 15px;
  }
}

.inviteContributeSlideCenter {
  .inviteContributeSlide {
    position: fixed;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    .btn-primary {
      right: -210px;
    }
  }
}

table.communityMeetingTable {
  tr {
    th,
    td {
      // &:nth-child(3) {
      //     width: 165px;
      // }
      &:nth-child(3) {
        width: 200px;
        //  display: flex;
        //  justify-content: space-between;
      }
      &:nth-child(7) {
        width: 100px;
      }
      &:nth-child(8) {
        // border-left: 3px solid transparent;
        width: 15px;
        background-color: white;
        cursor: auto;
        .meetings-icons {
          padding-right: 7px !important;
          .dashboard-icons {
            width: 12px !important;
            height: 17px !important;
            padding: 0px;
            margin: 0px;
          }
          .fa-tree {
            font-size: 17px !important;
          }
          .fa-bar-chart {
            font-size: 17px !important;
          }
          .fa-print {
            font-size: 16x !important;
          }
        }
        .openGCPopup {
          position: absolute;
          right: 19px;
          -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
          font-size: 16px;
          top: 7px;
        }
      }
      // &:nth-child(7) {
      //     width: 200px;
      //     .form-control {
      //         height: calc(1em + 0.75rem + 2px);
      //         padding: 0.25rem 0.75rem;
      //         font-size: 12px;
      //     }
      // }

      &:nth-child(5) {
        width: 100px;
      }
      &:nth-child(5),
      &:nth-child(3) {
        .form-control {
          height: calc(1em + 0.75rem + 2px);
          padding: 0.25rem 0.75rem;
          font-size: 12px;
        }
      }
      // &:nth-child(5){
      //     width:150px;
      //     .form-control {
      //         height: calc(1em + 0.75rem + 2px);
      //         padding: 0.25rem 0.75rem;
      //         font-size: 12px;
      //     }
      // }
      // &:nth-child(6)
    }
    &.childGoal {
      td {
        &:nth-child(1) {
          background-color: #f0f0f0 !important;
        }
        &:nth-child(2) {
          padding-left: 30px;
        }
      }
    }
  }
  .MuiSlider-root {
    position: relative;
    padding: 0;
  }
  .MuiSlider-rail {
    left: 0;
  }
  .MuiSlider-thumb {
    margin-left: 0;
  }
  .progresspercentage {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83px;
    flex: 0 0 83px;
    max-width: 83px;
  }
}
.sliderAction {
  width: auto !important;
  margin: 0 !important;
  input[type="range"] {
    -webkit-appearance: none;
    margin: 20px 0px 20px 35px;
    width: calc(100% - 35px);
    background: rgba(255, 165, 0, 0.5);
    &::-webkit-range-progress {
      background-color: orange;
      height: 4px;
    }
    &:focus {
      outline: none;
      &::-webkit-slider-runnable-track {
        // background: orange;
      }
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      animate: 0.2s;
      color: orange;
      border-radius: 25px;
      &:after {
        background-color: orange;
      }
    }
    &::-webkit-slider-thumb {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #fff;
      // box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
      border: 2px solid orange;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
    }
  }
  input[type="range"] {
    &::-moz-range-track {
      background: rgba(255, 165, 0, 0.5);
    }
    &::-moz-range-thumb {
      border-color: orange;
      background: #ffffff;
    }
  }
}
.sliderActionBlue {
  width: auto !important;
  margin: 0 !important;
  input[type="range"] {
    -webkit-appearance: none;
    margin: 20px 0px 20px 35px;
    width: calc(100% - 35px);
    background: rgba(0, 142, 204, 0.5);
    &::-webkit-range-progress {
      background-color: #008ecc;
      height: 4px;
    }
    &:focus {
      outline: none;
      &::-webkit-slider-runnable-track {
        // background: orange;
      }
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      animate: 0.2s;
      color: #008ecc;
      border-radius: 25px;
      &:after {
        background-color: #008ecc;
      }
    }
    &::-webkit-slider-thumb {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #fff;
      // box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
      border: 2px solid #008ecc;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
    }
  }
  input[type="range"] {
    &::-moz-range-track {
      background: rgba(0, 142, 204, 0.5);
    }
    &::-moz-range-thumb {
      border-color: #008ecc;
      background: #ffffff;
    }
  }
}
.sliderActionGreen {
  width: auto !important;
  margin: 0 !important;
  input[type="range"] {
    -webkit-appearance: none;
    margin: 20px 0px 20px 35px;
    width: calc(100% - 35px);
    background: rgba(11, 102, 35, 0.5);
    &::-webkit-range-progress {
      background-color: #0b6623;
      height: 4px;
    }
    &:focus {
      outline: none;
      &::-webkit-slider-runnable-track {
        // background: orange;
      }
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      animate: 0.2s;
      color: #0b6623;
      border-radius: 25px;
      &:after {
        background-color: #0b6623;
      }
    }
    &::-webkit-slider-thumb {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #fff;
      // box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
      border: 2px solid #0b6623;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
    }
  }
  input[type="range"] {
    &::-moz-range-track {
      background: rgba(11, 102, 35, 0.5);
    }
    &::-moz-range-thumb {
      border-color: #0b6623;
      background: #ffffff;
    }
  }
}
#improvement {
  height: 130px;
  background: #f6f6f6;
}
.communityMeetSS {
  .table_view {
    td {
      font-size: 14px;
      &:nth-child(2) {
        width: 340px;
        min-width: 340px;
        max-width: 340px;
      }
      &:nth-child(4) {
        width: 26px;
      }
      // &:nth-child(8) {
      //     width: 200px;
      //     .form-control {
      //         height: calc(1em + 0.75rem + 2px);
      //         padding: 0.25rem 0.75rem;
      //         font-size: 12px;
      //     }
      // }
      &:nth-child(5) {
        width: 80px;
        .form-control {
          height: calc(1em + 0.75rem + 2px);
          padding: 0.25rem 0.75rem;
          font-size: 12px;
        }
      }
      // &:nth-child(5),&:nth-child(6),&:nth-child(6)
      p {
        &:first-child {
          font-size: 14px;
          font-weight: 400;
          font-family: acumin-pro, sans-serif;
        }
      }
    }
  }
  .tProgress {
    &.disabled {
      opacity: 1;
    }
    .openGCPopup {
      position: absolute;
      right: 3px;
      transform: rotate(90deg);
      font-size: 16px;
    }
  }
  .floatingBtn {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 1;
  }
  .popover-body {
    max-height: 150px;
    overflow: auto;
  }
}

.MyCommunityGrowthPlans {
  .activationTabs {
    border: 1px solid map-get($colors, PrimaryBlue);
    background: #fff;
    span {
      color: map-get($colors, PrimaryBlue);
      padding: 8px 15px;
      color: map-get($colors, PrimaryBlue);
      font-size: 13px;
      display: inline-block;
      cursor: pointer;
      &.active {
        background: map-get($colors, PrimaryBlue);
        color: #fff;
      }
    }
  }

  .myTeamPlanTable {
    tr {
      td {
        vertical-align: middle;
        &:nth-child(1) {
          @include tableWidth(15%);
        }
        &:nth-child(2) {
          @include tableWidth(30%);
        }
        &:nth-child(3) {
          @include tableWidth(120px);
        }
        &:nth-child(4) {
          @include tableWidth(280px);
          @media (min-width: 991px) and (max-width: 1200px) {
            @include tableWidth(180px);
          }
        }
        &:nth-child(5) {
          @include tableWidth(100px);
          text-align: left;
          @media (min-width: 991px) and (max-width: 1200px) {
            @include tableWidth(60px);
          }
        }
      }
      &.subRowSection {
        td {
          padding-top: 5px;
          padding-bottom: 5px;
          &.contributorName {
            font-size: 14px;
          }
        }
      }
    }
  }
}

$spacing: 100px;
.communityMeeting {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  .meetnowVideoContainer,
  .App,
  .resolution1280x720px,
  .mx-auto.row {
    height: 100%;
    display: flex;
    flex-flow: column;
    margin: 0 !important;
  }
  .meetnowVideoContainer {
    @include colWidth(100%);
    max-height: 100%;
    .gray-box {
      padding: 0 !important;
      height: 100%;
      display: flex;
      flex-flow: column;
      .leftSideVideo {
        padding: $spacing $spacing 0 !important;
        margin: 0 !important;
        border-radius: 0;
        height: 100% !important;
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: center;
        #cameraPublisherContainer,
        #cameraSubscriberContainer {
          position: relative !important;
          top: 85px !important;
          width: auto !important;
          height: $spacing !important;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          flex-flow: row;
          margin: -$spacing 0 0 0;
          z-index: 1 !important;
          .OT_publisher,
          .OT_subscriber {
            min-width: $spacing;
            min-height: $spacing;
            border-radius: 50% !important;
            // margin: 0 20px;
            margin: 0 6.15vh;
            z-index: 1 !important;
            .OT_widget-container {
              background: transparent;
            }
            .OT_mode-off {
              &.OT_active {
                display: block;
                opacity: 1;
                top: auto;
                bottom: 0;
                background-size: 16px;
              }
            }
          }
          .OT_publisher {
            margin-left: 0;
          }
          .OT_subscriber {
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(11),
            &:nth-child(12),
            &:nth-child(13) {
              position: fixed;
              width: $spacing !important;
              height: $spacing !important;
              margin: 0;
            }
            &:nth-child(8),
            &:nth-child(10),
            &:nth-child(12) {
              left: 0;
            }
            &:nth-child(9),
            &:nth-child(11),
            &:nth-child(13) {
              right: 0;
            }
            &:last-child,
            &:nth-child(7) {
              margin-right: 0 !important;
            }
            // &:nth-child(6) {
            //     top: 85px !important;
            // }
            // &:nth-child(7) {
            //     top: 85px !important;
            // }
            &:nth-child(8) {
              top: calc(100px + (6vw * 1.5)) !important;
            }
            &:nth-child(9) {
              top: calc(100px + (6vw * 1.5)) !important;
            }
            &:nth-child(10) {
              top: calc(100px + (6.15vw * 3)) !important;
            }
            &:nth-child(11) {
              top: calc(100px + (6.15vw * 3)) !important;
            }
            &:nth-child(12) {
              top: calc(100px + (6.15vw * 4.5)) !important;
            }
            &:nth-child(13) {
              top: calc(100px + (6.15vw * 4.5)) !important;
            }
          }
        }
        #screenPublisherContainer {
          .sharescreenhide {
            display: none;
          }
        }
        #screenSubscriberContainer {
          &:not(:empty) {
            position: fixed;
            height: calc(100% - 45px);
            z-index: 99;
            top: 0;
            overflow: hidden;
            // .OT_fit-mode-contain .OT_video-element {
            //     object-fit: fill;
            // }
          }
          .OT_widget-container {
            background-color: #2f353a;
          }
        }
      }
      .videoControls {
        padding: 0.5rem 100px !important;
        justify-content: flex-end;
        .ots-video-control {
          width: 30px;
          height: 30px;
          background-size: 15px;
          &.video,
          &.end-call {
            background-size: 17px;
          }
          &.share-screen {
            background-size: 30px;
          }
        }
      }
    }
  }
  .min-height-card {
    .card-body {
      max-height: 53vh !important;
    }
  }
}

.scrollableSection {
  padding: $spacing $spacing 0;
  // display: none;
  .bg-white {
    height: calc(100vh - 245px);
    overflow: auto;
    position: relative;
    border-radius: 20px;
    .rdw-option-wrapper {
      padding: 2px;
      min-width: 25px;
      margin: 0 2px;
    }
    #custom-text-editor {
      .rdw-editor-toolbar {
        padding: 2px 2px 0;
      }
    }
    .rdw-dropdown-selectedtext {
      a {
        font-size: 12px;
      }
    }
    .rdw-block-dropdown,
    .rdw-fontfamily-dropdown {
      width: 85px;
      a {
        font-size: 12px;
      }
    }
  }
}
.sliderRating {
  .sliderAction {
    width: 250px !important;
    max-width: 250px;
    background-color: transparent;
    input[type="range"] {
      background: rgb(255, 165, 0);
      &::-webkit-slider-thumb {
        background-color: orange;
      }
    }
  }
  .sliderActionBlue {
    width: 250px !important;
    max-width: 250px;
    background-color: transparent;
    input[type="range"] {
      background: rgb(0, 142, 204);
      &::-webkit-slider-thumb {
        background-color: #008ecc;
      }
    }
  }
  .sliderActionGreen {
    width: 250px !important;
    max-width: 250px;
    background-color: transparent;
    input[type="range"] {
      background: rgb(11, 102, 35);
      &::-webkit-slider-thumb {
        background-color: #0b6623;
      }
    }
  }
}

$toggleHeight: 165px;
.toggleableNotes {
  position: absolute;
  top: $toggleHeight;
  bottom: 0;
  left: 35px;
  right: auto;
  // z-index: 1;
  margin: 0 !important;
  transition: 0.5s;
  @media (min-height: 781px) {
    top: $toggleHeight + 45px;
  }
  &.togglePrivateNotesActive,
  &.togglePublicNotesActive,
  &.toggleOpennotesActive {
    right: 35px;
  }
  .togglePrivateNotes,
  .togglePublicNotes,
  .toggleAllNotes {
    * {
      width: 0;
      height: 0;
      overflow: hidden;
      transition: 0.5s;
    }
  }
  &.togglePrivateNotesActive {
    .toggleableBtn.btn-red {
      opacity: 0;
      visibility: hidden;
    }
    .togglePrivateNotes {
      * {
        width: auto;
        height: auto;
        overflow: auto;
      }
    }
  }
  &.togglePublicNotesActive {
    .toggleableBtn.btn-green {
      opacity: 0;
      visibility: hidden;
    }
    .togglePublicNotes {
      * {
        width: auto;
        height: auto;
        overflow: auto;
      }
    }
  }
  &.toggleOpennotesActive {
    .toggleableBtn.btn-primary {
      opacity: 0;
      visibility: hidden;
    }
    .toggleAllNotes {
      * {
        width: auto;
        height: auto;
        overflow: auto;
      }
    }
  }
  .textEditorNotes {
    .rdw-editor-main {
      height: 15vh;
    }
    border-radius: 5px;
  }
  .toggleableBtnGroup {
    position: absolute;
    left: -22px;
    top: 50%;
    transform: rotate(90deg) translateX(-50%);
    transform-origin: left;
    display: flex;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
  }
  .btn {
    &.toggleableBtn {
      color: #fff;
      transition: 0.5s;
      opacity: 1;
      visibility: visible;
      white-space: nowrap;
      min-width: 110px;
    }
    &.btn-gray {
      width: auto;
      padding: 5px 12px;
    }
    &.btn-green {
      background: #4eb968;
      margin-right: 5px;
    }
    &.btn-red {
      background: #df445f;
    }
  }
  .toggleScroll {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
}

.msg-success .MuiPaper-elevation6 {
  background-color: #0b6623;
}
.msg-error .MuiPaper-elevation6 {
  background-color: red;
}

.GCpopup {
  &.show {
    .modal-dialog {
      transform: translateY(290px);
    }
  }
  .modal-dialog {
    max-width: 60%;
    max-height: 50%;
    .modal-content {
      border-radius: 10px;
      border: 2px solid #0b6623;
      .modal-body {
        min-height: 250px;
        max-height: 55vh;
        overflow: auto;
      }
    }
  }
  .filterbox {
    overflow: initial !important;
  }
}
.microMentorTable {
  &.table_view {
    tr {
      &.subRow {
        td {
          &:first-child {
            background-color: #f0f0f0;
          }
          p {
            &:first-child {
              width: 90%;
              margin-left: 8% !important;
            }
          }
        }
      }
      th,
      td {
        padding: 5px;
        &:nth-child(2) {
          text-align: left;
        }
        &:nth-child(3) {
          width: auto;
          text-align: left;
        }
        &:nth-child(4) {
          width: 150px;
          text-align: center;
        }
      }
    }
  }
}

.no-video {
  width: 100%;
  background-color: white;
  height: 75px;
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 82px;
}
.no-video-link {
  width: 193px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  line-height: 2;
  text-align: center;
  color: black;
  text-decoration: none;
  .btn-link {
    font-size: 12px !important;
    padding: 6px 20px !important;
    background-color: rgb(45, 188, 225) !important;
  }
}
.report-button {
  position: fixed;
  top: 34%;
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
  right: -27px;
  z-index: 100;
}
.reportslidingRight,
.reportslidingLeft {
  position: fixed;
  right: 40px;
  top: 50%;
  transform: translate(350px, -50%);
  width: 310px;
  opacity: 0;
  transition: 0.5s;
  /* display: none; */
  /* -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;  */
  .card {
    margin: 0;
  }
}
.reportslidingRight {
  opacity: 1;
  transform: translate(0, -50%);
  + .fixed-button i {
    transform: rotate(180deg);
  }
  z-index: 100;
}
.reportrightslider {
  background-color: #20a8d8;
  color: white;
  .main-div {
    padding: 26px;
    color: white;
  }
}
.slider-btn {
  margin-top: -39px;
  padding: 5px;
  margin-right: 25px;
  .btn {
    padding: 5px;
  }
}
.apexcharts-menu-icon {
  display: none !important;
}
ul.recharts-default-legend {
  display: none;
}

.custom-tooltip {
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 9px -6px #000;
  // box-shadow: 0px 3px 8px 0px #3232470D;

  p {
    margin: 0;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    span {
      margin-right: 10px;
    }
    &:nth-child(1) {
      color: #333;
    }
    &:nth-child(2) {
      color: #413ea0;
    }
    &:nth-child(3) {
      color: #ff7300;
    }
  }
}
.file-input-wrapper {
  height: 30px;
  margin: 2px;
  overflow: hidden;
  position: relative;
  width: 118px;
  background-color: #fff;
  cursor: pointer;
}

.file-input-wrapper > input[type="file"] {
  font-size: 40px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.file-input-wrapper > .btn-file-input {
  background-color: #61b4e4;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  height: 34px;
  margin: 0 0 0 -1px;
  padding-left: 0;
  width: 121px;
  cursor: pointer;
  border-color: #61b4e4;
}
#img_text {
  float: right;
  margin-right: -80px;
  margin-top: -14px;
}
.file-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: solid;
  overflow: auto;
  min-height: 100px;
  max-height: 180px;
  padding: 0;
  list-style: none;
  border-color: #0c7dd6;
  border-radius: 5px;
  li {
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      i {
        opacity: 1;
      }
    }
    &:nth-child(odd) {
      background-color: #eeeaea;
    }
    a {
      color: #636364;
      line-height: 1;
      &:hover {
        text-transform: none;
      }
    }
    i {
      width: 22px;
      height: 22px;
      border: 2px solid #0c7dd6;
      border-radius: 50%;
      color: #0c7dd6;
      text-align: center;
      line-height: 1;
      opacity: 0;
      transition: 0.5s;
    }
  }
}
.editor-desc {
  .rdw-editor-toolbar {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    > * {
      flex-wrap: nowrap;
    }
  }
  #custom-text-editor {
    margin-top: 0 !important;
  }
}
.desc-editor {
  .fa {
    position: absolute;
    left: 484px;
    color: #0197cc;
    top: 175px;
    border: solid 2px;
    border-radius: 19px;
  }
  #custom-text-editor {
    margin-top: 0 !important;
  }
  #custom-text-editor .rdw-editor-toolbar {
    padding: 1px;
  }
}
.goal-editor {
  .fa {
    left: 470px;
    top: 89px;
  }
}
.goalsup-editor {
  .fa {
    left: 303px;
    top: 157px;
  }
}
.attach-icon i {
  color: #0c7dd6;
  transform: rotate(43deg);
  font-size: 19px;
}
.file-popup .modal-dialog .modal-content .modal-body {
  min-height: auto;
}

.community-page .selectgoalhover button:hover .hoverdisplayblock {
  display: none !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  opacity: 1;
}
.community-page .selectgoalhover button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.community-page .action-list button .hoverdisplayblock {
  display: none !important;
}

.inviteContributeSlide .toggleableNotes {
  overflow: visible;
}
.gp-notes {
  position: fixed;
  bottom: auto;
  top: 55%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  // width: 1000px;
  min-height: 616px;
  max-height: 616px;
  bottom: 20%;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 999;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  .toggleableNotes {
    // width: 906px !important;
    top: 165px !important;
    .toggleScroll {
      margin-left: 14px;
    }
    .toggleableBtnGroup {
      height: 46px;
      left: -12px;
      top: 115px;
      transform: rotate(90deg);
      .btn.toggleableBtn {
        // padding: 20px;
        font-size: 16px;
      }
    }
    &.togglePrivateNotesActive,
    &.togglePublicNotesActive {
      width: 906px !important;
    }
  }
}
// .gp-notes .toggleableNotes .togglePublicNotesActive{
//     width: 906px !important;
// }
.notes-tab .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
  outline: 0;
}
.notes-loader .LoadingDot {
  margin: 30px;
}
.nav-pills .nav-link.active{
  background-color: transparent;
  border-radius: 0px;
  color: inherit;
  border-bottom: 2px solid #695de9;

}
.work-plan .toggleableBtnGroup {
  top: 20% !important;
}
.work-plan .cardBorderContentprimary .rdw-editor-toolbar {
  display: none;
}
.work-plan {
  .btn.btn-primary:not(.btn-sm) {
    padding: 7px 20px !important;
  }
  .scrollableSection {
    // padding: 75px 75px 0 !important;
    padding: 0 !important;
  }
  .toggleableNotes .textEditorNotes {
    .cardBorderContentprimary {
      .rdw-editor-main {
        height: auto;
      }
      #custom-text-editor .DraftEditor-root {
        height: 146px;
      }
    }
    border-radius: 5px;
  }
  .scrollableSection .bg-white {
    height: calc(100vh - 86px) !important;
    overflow: auto;
    position: relative;
    border-radius: 0px !important;
  }
  .toggleableNotes {
    top: 309px;
  }
}
.workplan-link:not(:empty) + .work-plan-scroll .bg-white {
  height: calc(100vh - 140px) !important;
  // height: 100vh !important;
}
.workplan-link:is(:empty) + .work-plan-scroll .bg-white {
  height: calc(100vh - 94px) !important;
  // height: 100vh !important;
}
.notes-content {
  min-width: 513px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: auto;
  height: 500px;
  // margin-top: 17px;
}
.history-heading {
  margin-right: 8px;
  font-size: 14px;
}

.notespage {
  // padding: 0px;
  // margin-top: 31px;
  .btn {
    margin: 2px;
  }
}

.rdw-option-disabled {
  display: none !important;
}

.notesdata * {
  font-size: 15px !important;
}
// 22.01.2021
.modal-dialog.goal-modal {
  display: flex;
  /* margin: 9px; */
  margin: 10.75rem auto;
}

.goal-link-upload img {
  width: 60px;
  height: 60px;
}
.upload-img,
.link-img {
  display: flex;
  flex-direction: column;
}
.goal-modal-header {
  border: none;
  // padding: 0rem 1rem;
  .slick-arrow-slick-next {
    display: none;
  }
}
.goal-link-upload {
  .upload-img {
    position: relative;
    input {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      opacity: 0;
      z-index: 1;
    }
  }
}
input.blueBorderedInput.form-control {
  border-color: #0197cc;
}

.meeting-invite .inviteContributeSlide {
  position: fixed;
  bottom: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999999999;
}

.meeting-invite {
  .autoComplete {
    > div {
      display: block !important;
      > input + div {
        left: 16px !important;
        top: 38px !important;
      }
    }
  }
  .action-list {
    .btn {
      width: 100% !important;
    }
  }
  .inviteContributeSlide.active {
    width: 100%;
    position: absolute;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: auto !important;
    top: -60px;
    transform: translate(0);
    height: calc(100vh - 265px) !important;
    min-height: calc(100vh - 265px) !important;
    max-height: calc(100vh - 265px) !important;
    top: -165px;
    @media (min-height: 781px) {
      top: -210px;
    }
    // top: -23%;
    // transform: translateY(-23%);
    .closebuton .btn-gray {
      width: 100px !important;
      align-self: flex-end;
      margin-top: 2px;
    }
  }
  .toggleableBtnGroup {
    top: -27px !important;
  }
  .toggleableNotes.toggleOpennotesActive .toggleAllNotes * {
    overflow: initial;
    // width: 100% !important;
  }
  .goalAction .goalList {
    height: 200px !important;
    overflow-y: scroll !important;
  }
}
.inviteContributeSlideCenter .inviteContributeSlide .btn-primary {
  top: 193px;
  right: -145px !important;
  min-width: 122px;
  background-color: #752b8d !important;
  border-color: #752b8d !important;
}
.gpContainer .inviteContributeSlide .btn-primary {
  top: 208px;
  right: -88px !important;
  background-color: #752b8d !important;
  border-color: #752b8d !important;
}
.toggleableNotes .btn.btn-primary {
  background: #4eb968;
  margin-right: 5px;
}
.action-align {
  .selected-goal-action-list {
    .action-list {
      width: 100%;
      span:first-child {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
      }
    }
  }
}
.notes-header {
  display: flex;
  flex-direction: row;
}
.notes-editor-btn {
  display: flex;
  .btn {
    padding: 3px;
    margin: 0px;
  }
}
.dashboardContent {
  .card-toggle {
    position: absolute;
    margin-top: 10px;
    left: 7px;
  }
}
.gp-html {
  p {
    background: none !important;
  }
}
.popup-editor {
  #custom-text-editor .DraftEditor-root {
    height: 200px !important;
  }
}
// screen share----
.opentok-screenshare {
  .app-header {
    height: 50px !important;
    *:not(.container-fluid) {
      display: none;
    }
    .navbar-brand {
      justify-content: flex-start;
      height: 40px;
      display: block;
      .navbar-brand-full {
        max-height: 100%;
        width: auto;
        display: block;
      }
    }
  }
  .communityMeeting {
    .meetnowVideoContainer {
      .gray-box {
        .leftSideVideo {
          display: flex;
          padding: 50px 0px 0 !important;
          // #cameraPublisherContainer, #cameraSubscriberContainer {
          // top: 30px !important;
          // }
          #screenSubscriberContainer {
            &:not(:empty) {
              // height: calc(100% - 185px);
              // top: 135px;
              position: relative;
              order: -1;
              height: 100%;
            }
          }
          .publisherSubscriberContainer {
            width: 30vw;
            flex-flow: column;
            align-items: center !important;
            #cameraPublisherContainer,
            #cameraSubscriberContainer {
              top: auto !important;
              margin: 0 !important;
              flex-wrap: wrap;
              .OT_subscriber,
              .OT_publisher {
                margin: 0 auto 15px !important;
                width: auto !important;
                height: auto !important;
                .OT_widget-container {
                  min-width: 100px;
                  max-width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.publisherSubscriberContainer {
  align-items: flex-start;
  display: flex;
}

// screen share----

.goalbutton-style {
  padding: 6px 7px 6px 0px;
  background-color: #752b8d !important;
  font-size: 14px;
  // margin-right:19px;
  margin-right: 60px;
  color: white;
  :hover {
    color: white;
  }
}
.goalbutton {
  padding: 0px;
}
.dashboard-icons {
  line-height: 1;
  background-color: transparent !important;
  width: 20px !important;
  height: 20px;
  // border-radius: 25px;
  border: none;
  padding: 0;
  svg {
    height: 100%;
  }
}
.progress-mouse-over {
  position: relative;
  width: calc(100% - 83px);
  .progress {
    width: 100% !important;
  }
  .progress-mouse-over-value {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    > div {
      position: relative;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 17px;
        transition: 0.5s;
      }
      &:hover {
        &:before {
          content: attr(data-date);
          font-size: 11px;
          text-align: center;
          display: inline-block;
          background: #fff;
          padding: 2px 5px;
          white-space: nowrap;
          -webkit-box-shadow: 0 0 5px -2px #333;
          box-shadow: 0 0 5px -2px #333;
          border-radius: 5px;
        }
      }
    }
  }
}

/* 28-05-2021 
   Progress multi mouse over */
.progress-mouse-over {
  position: relative;
  width: calc(100% - 83px);
  .progress {
    width: 100% !important;
  }
  .progress-mouse-over-value {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    .hover-percentage {
      position: relative;
      text-align: center;
      white-space: pre;
      display: inline-block;
      .hover-position {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 17px;
        transition: 0.5s;
      }
      &:hover {
        .hover-position {
          display: flex;
          flex-flow: column;
          font-size: 11px;
          text-align: center;
          background: #fff;
          padding: 2px 5px;
          box-shadow: 0 0 5px -2px #333;
          border-radius: 5px;
        }
      }
    }
  }
}
.modal-dialog.modal-fullwidth {
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: 100%;
  .modal-header {
    justify-content: center;
    .close {
      position: absolute;
      right: 30px;
    }
  }
}
.Growthplans_head .btn {
  padding: 6px 7px 6px 0;
  background-color: #752b8d !important;
  font-size: 14px;
  margin-right: 19px;
  color: #fff;
  border-color: #752b8d !important;
}
.toggleableBtnGroup .btn-primary {
  background-color: #752b8d !important;
  border-color: #752b8d !important;
}
.bg-blue-img {
  // padding-top: 0rem !important;
  background-size: 100% 100%;
  width: 100%;
  height: 226px;
  &.bg-blue-gradiant {
    padding-top: 0.5rem !important;
  }
  .card {
    // background: transparent;
    background-color: #f1ebeb40;
    color: white;
    font-weight: bold;
    .card-header {
      background: transparent !important;
    }
  }
  .col-12 {
    padding-left: 0px;
    padding: 0px !important;
  }
  // .rdw-editor-toolbar{
  //     background: transparent !important;
  // }
  // #custom-text-editor .demo-editor.rdw-editor-main{
  //     background: transparent !important;
  // }
  // .rdw-option-wrapper{
  //     background: transparent !important;
  // }
}
// .element {
//     display: inline-flex;
//     align-items: center;
//   }
//   i.fa-camera {
//     margin: 10px;
//     cursor: pointer;
//     font-size: 30px;
//   }
//   i:hover {
//     opacity: 0.6;
//   }
//   input {
//     display: none;
//   }

.image-upload > input {
  display: none;
}

.image-upload {
  margin-left: auto;
  i {
    cursor: pointer;
    font-size: 25px;
    position: relative;
    color: #ffffff;
    top: 7px;
  }
}
.file-wrapper {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
}
.file-wrapper input {
  cursor: pointer;
  font-size: 100px;
  filter: alpha(opacity=1);
  -moz-opacity: 0.01;
  opacity: 0.01;
  position: absolute;
  right: 0;
  top: 0;
}
.file-wrapper .button {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  margin-right: 5px;
  padding: 4px 18px;
}
.lable-color {
  color: #752b8d !important;
}
// .navbar-brand {
//     img {
//         width: 100%;
//         height: 100%;
//         // object-fit: cover;
//     }
// }

.navbar-brand img {
  width: 300px;
  height: auto;
  object-fit: cover;
}
.navbar-brand {
  margin-left: 0px !important;
  width: 300px !important;
  height: 70px !important;
  overflow: hidden;
}
.card-text-6f4b93 * {
  color: #6f4b93;
}
.card-text-456945 * {
  color: #456945;
}
.card-text-b7274b * {
  color: #b7274b;
}
.card-text-e77b57 * {
  color: #e77b57;
}
.card-text-934b6f * {
  color: #934b6f;
}
.card-text-6f6f6f * {
  color: #6f6f6f;
}
.card-text-218d8d * {
  color: #218d8d;
}
.card-text-27b74b * {
  color: #27b74b;
}
.card-text-bd51bd * {
  color: #bd51bd;
}
.card-text-2dbce1 * {
  color: #2dbce1;
}
.card-text-fff * {
  color: #fff;
}
.card-text-139bcc * {
  color: #139bcc;
}
.card-group {
  .color-picker {
    background: #d3d3d3d6;
  }
  .card-edit {
    position: absolute;
    top: 3px;
    right: 40px;
    .color-picker {
      top: 70px;
    }
  }
  .CP-Dashboard-card .card {
    height: 210px !important;
  }
  .editor-button {
    position: absolute;
    top: 10px;
    right: 15px;
  }
}
.header-meetings {
  .dropdown-menu {
    min-width: 18rem !important;
  }
  .meeting-border,
  h5 {
    display: none;
  }
}

.popover {
  max-width: 600px !important;
}

.custom_dropdown {
  position: relative;
  top: 4px;
  margin: 0 auto;
  width: auto;
  z-index: 1;
  font-size: 16px;
}

.dropdown__text {
  color: white;
  padding: 10px 0px;
  cursor: pointer;
  height: auto;
  i {
    font-size: 25px;
    cursor: pointer;
    text-shadow: 0 2px 0px #000;
  }
}

.dropdown__text:hover {
  color: white;
}

.custom_dropdown.active .dropdown__text:after {
  margin-top: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown__items {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: 0.5s ease;
  right: 0;
  width: 150px;
  top: 100%;
  background: #475154;
}

.custom_dropdown.active .dropdown__items {
  visibility: visible;
  border-top: 1px solid #666;
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: 0.5s ease;
  font-size: 11px;
}

.dropdown__item {
  cursor: pointer;
  padding: 5px 18px;
}

.dropdown__item:not(:last-child) {
  border-bottom: 1px solid #666;
}

.dropdown__item:hover {
  color: white;
}

.trainingItem:hover {
  color: black !important;
}
.dropdown__item input#file-input1 {
  display: none;
}
.dropdown__item input#file-input2 {
  display: none;
}
.dropdown__item input#file-input3 {
  display: none;
}

.settingsHolder {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  svg {
    filter: drop-shadow(0px 2px 0px #000);
  }
  .color-picker {
    top: 61px;
  }
}
.img-cropper-modal {
  min-width: 100%;
}
.expertise-autocomplete > div {
  position: relative;
}

.disable-slider {
  pointer-events: none;
  cursor: none;
}
.admin-even-with-btn .btn {
  min-width: 205px;
}
.actionfeedbackpopup .modal-dialog {
  max-width: 480px;
}
.actionNameAnimate:hover {
  transform: scale(1.05);
}

.actionNameAnimate {
  transform: scale(1);
  transition: 0.5s;
}
.Animatepercentag:hover {
  transition: 0.5s;
  transform: scale(1.2);
}

.goaldescpopover {
  max-width: 500px;
  width: 100%;
  transform: translate3d(77px, 209px, 0px) !important;
  .popover-body {
    max-height: 37vh;
    overflow: auto;
  }
  .arrow {
    left: 100px !important;
  }
}
.headsUp-thumbs {
  transition: 0.5s;
  &:hover {
    transform: scale(1.3);
    color: green;
  }
}
.custom-breadcurmb {
  font-size: 25px;
  i {
    transform: rotate(90deg);
    font-size: 19px;
    position: relative;
    color: white;
    margin-left: -16px;
    font-weight: normal;
  }
  .share-active {
    cursor: pointer;
  }
  .breadcrumb {
    padding: 0;
    padding-left: 17px;
    margin: 0;
    background-color: #752b8d !important;
    .first-element {
      padding-left: 26px;
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: white;
    content: ">";
    // content: "/";
    font-weight: bold;
  }
  .breadcrumb-item {
    a {
      color: white !important;
      text-decoration: none !important;
      background-color: transparent;
      cursor: pointer;
    }
  }
  .breadcrumb-item.active {
    color: white !important;
    a {
      color: white !important;
      background-color: transparent;
      cursor: default;
    }
  }
}
.share-icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 19px;
}
.goal-scroll {
  max-height: 222px !important;
  overflow-y: auto;
  width: 347px;
  background-color: #fff;
  margin: 0 0 10px 0;
}
.modal-title,
.app-header {
  .navbar-brand img {
    width: 300px;
    height: auto;
    -o-object-fit: cover;
    object-fit: contain;
    max-height: 100%;
  }
}
.company-card {
  .card {
    z-index: 2;
    height: 210px !important;
  }
  .card-edit {
    position: absolute;
    top: 3px;
    right: 40px;
    .color-picker {
      top: 70px;
    }
  }
  .editor-button {
    position: absolute;
    top: 10px;
    right: 15px;
  }
  .data-style {
    text-align: left;
    min-height: 50px;
    max-height: 155px;
    overflow-y: auto;
    margin: 0px !important;
    font-weight: 400;
    color: black;
  }
}
.actionpopup {
  .modal-dialog {
    -webkit-transform: translateY(142px);
    -ms-transform: translateY(72px);
    transform: translateY(72px) !important;
    max-width: 33% !important;
    // max-width: 50% !important;
    // max-height: 50% !important;
  }
  .goalAction .goalList {
    width: 343px !important;
  }
  .modal-header {
    border-top-left-radius: 33px;
    border-top-right-radius: 33px;
    .text-center {
      font-size: 25px !important;
    }
    .btn-close {
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #fff;
      border-radius: 50%;
      color: #fff;
      top: 10px;
      right: 10px;
      position: relative;
      transition: 0.5s;
      &:hover {
        transform: rotate(180deg);
        opacity: 1;
      }
    }
  }
  .modal-body {
    min-height: 150px !important;
  }
  .modal-content {
    border-radius: 36px !important;
    border-color: transparent !important;
  }
  .modal-footer {
    border-bottom-right-radius: 36px;
    border-bottom-left-radius: 36px;
  }
}
.addactionicon {
  &:hover {
    transition: 1s;
    transform: scale(1.3);
  }
}
.goalpopup {
  .hoverdisplayblock {
    display: none !important;
  }
  .tab-content {
    margin-top: 0px !important;
    background: transparent !important;
    border: none !important;
    .tab-pane {
      padding: 0px !important;
      .position-relative.form-group {
        text-align: center;
      }
    }
    .rdw-editor-toolbar {
      display: none !important;
    }
  }
}
.card-text-bg-6f4b93 {
  background-color: #6f4b93;
}
.card-text-bg-456945 {
  background-color: #456945;
}
.card-text-bg-b7274b {
  background-color: #b7274b;
}
.card-text-bg-e77b57 {
  background-color: #e77b57;
}
.card-text-bg-934b6f {
  background-color: #934b6f;
}
.card-text-bg-6f6f6f {
  background-color: #6f6f6f;
}
.card-text-bg-218d8d {
  background-color: #218d8d;
}
.card-text-bg-27b74b {
  background-color: #27b74b;
}
.card-text-bg-bd51bd {
  background-color: #bd51bd;
}
.card-text-bg-2dbce1 {
  background-color: #2dbce1;
}
.card-text-bg-fff {
  background-color: #fff;
}
.card-text-bg-139bcc {
  background-color: #139bcc;
}
/* Context menu css */

nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.react-contextmenu .react-contextmenu-item:hover {
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
  .fa-pencil-square-o {
    color: blue;
  }
  .fa-check {
    color: green;
  }
}
.react-contextmenu-item span {
  margin-left: 10px;
}
.react-contextmenu-item i {
  font-size: 14px;
}
.react-contextmenu-item .fa-plus {
  color: skyblue;
}
.react-contextmenu-item .fa-trash {
  color: red;
}
.react-contextmenu-item .fa-paperclip {
  color: blue;
}
.react-contextmenu-item .fa-file-text {
  color: green;
}

.react-contextmenu-item .fa-sort {
  color: rebeccapurple;
}
.plan-menu {
  width: 242px !important;
}
//meeting and work plan page attch file context menu desing
.bg-white {
  .react-contextmenu-item {
    div {
      margin-top: 0px !important;
    }
    .file-input-wrapper {
      height: 20px;
      margin: 0;
      width: 82px;
      .btn-file-input {
        border: none;
        background: transparent;
        color: #989898;
        border-radius: 4px;
        display: inline-block;
        height: 25px;
        padding-left: 0;
        width: 46px;
        cursor: pointer;
      }
    }
  }
  .meeting-file-upload {
    .file-list {
      display: none;
    }
  }
}
.react-contextmenu-item .file-input-wrapper {
  background: transparent;
}
.goal-header-hide {
  .rdw-editor-toolbar {
    display: none;
  }
}
.icon-color {
  .color-picker {
    top: 65px;
  }
}
.workplan-link {
  .custom-breadcurmb {
    margin-top: 5px;
    .breadcrumb {
      border-radius: 5px;
    }
  }
}

.work-plan-name:hover {
  text-decoration: underline;
  cursor: pointer;
}
.customPlanMenu {
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  outline: none;
}
.work-plan .file-input-wrapper {
  display: none;
}
.goal-menu .file-input-wrapper {
  display: flex;
  margin-left: 10px !important;
}
.publish-btn .react-contextmenu-wrapper {
  width: 85% !important;
  margin: 2px !important;
  overflow: hidden;
}

.sync-checkbox {
  padding-left: 5px;
  display: flex;
  align-items: center;
  height: 27px;
  width: 35px;
  flex: 0 0 35px;
  position: relative;
  font-size: 11px;
  justify-content: center;
  input {
    position: relative;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    + label {
      position: absolute;
      margin: 0px;
      &:before {
        content: "\f096";
        font-family: "FontAwesome";
        font-size: 24px;
      }
    }
    &:checked {
      + label {
        &:before {
          content: "\f14a";
        }
      }
    }
  }
}

.goalname-menu {
  > .react-contextmenu-wrapper {
    width: 100%;
  }
}

.select-disabled {
  select {
    background-color: #e4e7ea;
    opacity: 1;
    pointer-events: none;
  }
  // div {
  //     background-color:transparent;
  //     opacity: 1;
  //     pointer-events: none;
  // }
}

.meetings-icons {
  // .dashboard-icons{
  //     width: 15px !important;
  //     height: 15px !important;
  // }
  .dashboard-icons {
    width: 18px !important;
    height: 17px !important;
    padding: 0px;
    margin: 0px;
  }
}
.total-heading {
  display: inline-block;
  width: 96px;
}
.custom-sm {
  height: 24px !important;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 5px;
}
.sync-checkbox:before {
  content: "";
  position: absolute;
  left: 0;
  border-left: 1px solid #fff;
  height: 40px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.total-box {
  background-color: transparent;
  width: auto;
  padding-left: 2px;
  padding-right: 2px;
  cursor: default !important;
  height: 50px;
  border: none;
  left: -88px;
  color: black;
  font-weight: bold;
  top: -24px;
  position: absolute;

  // background-color: transparent;
  // width: auto;
  // padding-left: 2px;
  // padding-right: 2px;
  // cursor: default !important;
  // height: 25px;
  // border: none;
  // color: black;
  // font-weight: bold;
  // position: absolute;
  // top: -2px;
  // padding: 0px;
  // right: 4px;
}
// .tProgress{
.table-total-col {
  .form-control:focus {
    // background: transparent;
    // border: none;
    // box-shadow: none;
    // color: black;

    background: #e4e7ea;
    cursor: default;
  }
}
.new-goal-popup {
  -webkit-transform: translateY(142px);
  -ms-transform: translateY(142px);
  transform: translateY(142px) !important;
  .modal-header {
    border-top-left-radius: 33px;
    border-top-right-radius: 33px;
    .text-center {
      font-size: 25px !important;
    }
    .close {
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #fff;
      border-radius: 50%;
      color: #fff;
      top: 10px;
      right: 10px;
      position: relative;
      transition: 0.5s;
      &:hover {
        transform: rotate(180deg);
        opacity: 1;
      }
    }
  }
  .modal-content {
    border-radius: 36px !important;
    border-color: transparent !important;
  }
  .modal-footer {
    border-bottom-right-radius: 36px;
    border-bottom-left-radius: 36px;
  }
}
.goal-add-input {
  flex: 0 0 54.66667%;
  max-width: 50.66667%;
  padding: 0px;
  padding-right: 10px;
}
.goal-symbol {
  padding-left: 0px !important;
  padding-right: 0px !important;
  flex: 0 0 6.66667%;
  max-width: 23.66667%;
}
.goal-total {
  flex: 0 0 22.66667%;
  max-width: 26.66667%;
  padding-left: 0px;
  padding-right: 8px;
}
.total-col {
  background-color: #e4e7ea;
  width: 90px;
  padding-left: 2px;
  padding-right: 2px;
  cursor: default !important;
}
.measurement-icon {
  // margin-bottom: 0.5rem;
  cursor: pointer;
  &:hover {
    border: 2px solid white !important;
  }
}
.tGoalDate {
  .openGCPopup {
    position: absolute;
    right: 3px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size: 16px;
    top: 7px;
  }
  .react-datepicker__input-container {
    display: flex !important;
    padding-left: 10px;
  }
}
#react-select-2-listbox {
  .css-4ljt47-MenuList {
    text-align: left;
  }
}
.goal-col,
.action-col {
  display: flex;
  justify-content: space-between;
  div:nth-child(2) {
    width: 84%;
    padding: 1px;
  }
}
.tab-content .tab-pane {
  padding: 1rem !important;
}
.tab-content {
  background-color: white;
  border: 1px solid #c8ced3;
  border-top: none;
}
.nav-tabs {
  border-bottom: 1px solid #c8ced3;
}
.nav-link {
  color: inherit;
}
.btn-link {
  text-decoration: none;
}
.number-svg {
  padding: 2px;
}
.cursor-defalut {
  cursor: default !important;
}
.new-field {
  width: 100% !important;
  margin-bottom: 5px !important;
  hr {
    display: none !important;
  }
}
.training {
  .card {
    border: solid 1px rgba(0, 0, 0, 0.08);
  }
  .card:hover {
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.08);
  }
  .card-title {
    display: flex;
    flex-direction: column;
    .text {
      padding-left: 20px;
      font-weight: bold;
      // display: inline-block;
      width: 336px;
      height: 40px;
      // white-space: nowrap;
      // overflow: hidden !important;
      // text-overflow: ellipsis;
    }
  }
  .card-body {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  .avatar-img {
    width: 100%;
    img {
      // border: solid 6px transparent;
      height: 184px;
      width: -webkit-fill-available;
      object-fit: contain;
      margin: 8px;
      margin-bottom: 0;
      border-radius: 4px;
    }
  }
  .icon {
    display: flex;
    align-items: end;
    justify-content: end;
    font-weight: bold;
    i {
      font-size: 25px;
      cursor: pointer;
    }
    i:first-child {
      margin-right: 6px;
      color: green;
    }
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 30px;
    color: #0000008a;
    cursor: pointer;
  }
  //   .tab-content{
  //       border: none;
  //   }
  .nav-tabs {
    height: 100%;
    overflow: auto;
    border: 1px solid #003962;
    background-color: #003962;
    border-radius: 16px;
    // margin-left: 15px;
    //   border-bottom: none;
    //   .nav-link{
    //       border: none;
    //   }
    //   .nav-link.active {
    //       border-bottom: solid;
    //       border-bottom-color: green;
    //     }

    .MuiTab-root {
      color: #ffffff;
      align-items: flex-start;
      text-align: left;
      text-transform: unset;
    }
    .MuiTabs-scroller {
      overflow: auto !important;
    }
    .MuiTabs-scroller::-webkit-scrollbar {
      width: 3px; /* Width of the scrollbar */
      // height: 12px !important; /* Height of the scrollbar (for horizontal scrollbar) */
      padding-right: 8px;
    }

    .MuiTabs-scroller::-webkit-scrollbar-track {
      background: transparent; /* Background of the scrollbar track */
      // border-radius: 6px; /* Round the corners of the track */
    }

    .MuiTabs-scroller::-webkit-scrollbar-thumb {
      background-color: #6fa9ff; /* Color of the scrollbar thumb */
      border-radius: 6px; /* Round the corners of the thumb */
      // border: 3px solid #f1f1f1; /* Creates space around the thumb */
    }

    // .MuiTabs-scroller::-webkit-scrollbar-thumb:hover {
    //   background-color: #555; /* Color when hovering over the thumb */
    // }
    .MuiTabs-flexContainer {
      margin-top: 32px;
    }
    .Mui-selected {
      color: #003962 !important;
      font-weight: 500 !important;
      background-color: #ffffff !important;
    }

    .MuiTabs-indicator {
      background-color: transparent !important;
    }
  }
  .no-record {
    text-align: center;
    font-size: 46px;
  }
}
.training-attachment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.floating-window {
  z-index: 1051;
  position: fixed;
  background: transparent;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 10px;
  padding-bottom: 0px;
  margin: 0px;
  background-color: white;
  border: 2px solid #67bce7;
  border-radius: 9px;
  width: auto;
  height: auto;
  i {
    font-size: 25px;
    right: 3px;
    position: absolute;
    top: 0px;
    color: green;
  }
  #custom-text-editor {
    width: 200px;
    width: auto;
    height: auto;
    // .rdw-editor-toolbar{
    //     display: none;
    // }
    .DraftEditor-root {
      height: 125px;
      // resize: both;
      // overflow: auto;
    }
  }
  .rdw-emoji-wrapper {
    display: none;
  }
  .handle {
    background: transparent;
    color: transparent;
    width: 93%;
    height: 20px;
    position: absolute;
    top: 2px;
  }
  .demo-wrapper {
    resize: both;
    overflow: auto;
    height: 200px; // whatever height you want
    width: 100%;
    .demo-editor {
      height: 85%;
      width: 100%;
    }
  }
}
.select-yellow {
  select {
    color: yellow;
  }
}
.selected-red {
  select {
    color: red;
  }
}
.select-yellow-span {
  color: yellow;
  background-color: yellow;
}
.select-red-span {
  color: red;
  background-color: red;
}
.action-disabled {
  div:nth-child(2),
  div:nth-child(3) {
    visibility: hidden;
  }
}
.relatedplan {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 91px;
  text-decoration: underline;
  color: blue;
}
.trendReport {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  overflow: auto;
  .goalList {
    background-color: #f0f0f0;
  }
  .header {
    background: white;
    text-align: center;
    white-space: nowrap;
  }
  .main-ls {
    .first-div {
      border: 1px solid #fff;
    }
    flex-direction: column;
  }
  .goalNamemain {
    width: 200px;
    height: 42px;
    padding: 5px 0;
    background-color: #f0f0f0;
    border-right: 1px solid #fff;
    .goalName {
      padding: 2px 6px;
      color: #fff;
      border-radius: 5px;
      margin: 0;
      text-align: left;
      white-space: nowrap;
      width: 95%;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
  }
  .actionName {
    padding-left: 30px;
  }

  .percentCol {
    // width: 250px;
    padding: 5px;
    position: relative;
    display: flex;
    justify-content: space-around;
  }
}
.hideComplete {
  position: absolute;
  left: 32px;
  top: 14px;
  input {
    height: 18px;
    width: 18px;
  }
  // input:checked{
  //     background-color: green;
  // }
  input[type="checkbox"]:checked {
    background-color: green !important;
  }
  input[type="checkbox"]::before {
    background-color: green !important;
  }
}
.custom-checkbox #customCheck1:checked ~ .custom-control-label::before {
  background-color: green !important;
}

.doc-popup-img {
  p {
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.doc-popup-img:hover {
  img {
    filter: opacity(0.5);
  }

  .popup-close {
    display: block !important;
    // background-color: red;
    padding: 5px;
    p {
    }
  }
}

.file-popup {
  &.show {
    .modal-dialog {
      transform: translate(200px, 210px) !important;
    }
  }
  max-width: 70%;
}

.ql-hidden {
  display: none;
}

.carousel-control-prev {
  display: none;
}
.carousel-control-next-icon {
  display: none;
}
.carousel-indicators {
  display: none;
}
.carousel-control-next {
  width: 388px;
  height: 20px;
}

// comments
.NotesSec {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  color: #000;
  .NotesSec-text {
    width: fit-content;
    border-bottom: 1px solid #d0d0d0;
    .text {
      font-size: 1rem;
    }
    .commentsText {
      font-size: 1rem;
      color: #989898;
    }
  }
  .commentsText {
    background-color: transparent !important;
    padding: 0;
    font-size: 1rem;
    color: #989898 !important;
  }
  .NotesSec-profile {
    column-gap: 1rem;
    .NotesDate {
      margin: 0;
      font-size: 0.9rem;
      color: #989898;
    }
    .text {
      margin: 0;
      font-size: 1rem;
    }
  }
}

.commentSec {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 10px 0;
  .commentSec-input {
    gap: 1rem;
    .ql-toolbar.ql-snow {
      display: none;
    }
    .ql-snow {
      min-width: 500px;
      max-width: 500px;
      border: none;
      outline: none;
      background-color: #f1f2f6;
      border-radius: 50px;
    }
    .ql-editor p {
      font-size: 0.8rem;
    }
  }
}
.noteSec {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 10px 0;
  .noteSec-input {
    gap: 1rem;
    .quill {
      min-width: 90%;
    }
    .ql-toolbar.ql-snow {
      display: none;
    }
    .ql-snow {
      // min-width: 90%;
      border: none;
      outline: none;
      background-color: #f1f2f6;
      border-radius: 50px;
    }
    .ql-editor {
      max-height: 104px;
      padding: 16px 8px;
    }
    .ql-editor p {
      font-size: 0.8rem;
    }
  }
}
.trainingslider {
  width: 100%;
  .slick-list .slick-slide {
    // width: auto !important;
  }
  .slick-prev:before {
    display: none;
  }
  .slick-next:before {
    display: none;
  }
  .slick-arrow {
    cursor: context-menu;
  }
}
.input-box-style {
  width: 100%;
  border: none;
  outline: 0;
  border-bottom: 2px solid black;
}
.comment {
  width: 100%;
  display: flex;
  column-gap: 1rem;
  float: left;
  padding: 0 0 10px 0;
  .commentImage {
    border-radius: 50%;
    overflow: hidden;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
  }
  .commentChat {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    background-color: #f1f2f6;
    p {
      margin: 0;
      font-size: 0.9rem;
      width: fit-content;
    }
    .commentText {
      margin: 0;
      font-size: 0.9rem;
      width: fit-content;
    }
    .commentName {
      font-size: 0.9rem;
      color: #000;
      font-weight: 300;
      margin: 0;
    }
    .commentDate {
      font-size: 0.7rem;
      color: #000;
      font-weight: 200;
      width: fit-content;
      margin-top: -10px;
    }
  }
  .commentDelete {
    display: none;
    align-items: center;
  }
}
.comment:hover {
  .commentDelete {
    display: flex;
  }
}
.profile-img {
  border-radius: 50%;
}

.quill {
  .ql-container {
    color: #000 !important;
    overflow: auto;
  }
}

.decision-modal {
  .modal-header {
    color: #1F2857;
    background-color: #EFF6FF;
    border-bottom-color: #95A0FF;
    .modal-title {
      width: 100% !important;
    }
  }

  .quill{
    display: flex;
    flex-direction: column-reverse;
    .ql-container{
      border-top: 1px solid #ccc !important;
      border-bottom: 0px !important;
      height: 120px;
      background-color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .ql-toolbar{
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      
    }
  }
}
.floating-input{
  .MuiInputBase-input{
    padding: 6px 12px !important;
  }
}
// .hidecompleteCheckbox {
//     display: block;
//     position: relative;
//     padding-left: 35px;
//     margin-bottom: 12px;
//     cursor: pointer;
//     font-size: 22px;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     position: absolute;
//     left: 40px;
//   }

//   /* Hide the browser's default checkbox */
//   .hidecompleteCheckbox input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
//   }

//   /* Create a custom checkbox */
//   .checkmark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 20px;
//     width: 20px;
//     background-color:#f0f0f0;
//     // border: 2px black solid;
//   }

//   /* On mouse-over, add a grey background color */
//   .hidecompleteCheckbox:hover input ~ .checkmark {
//     background-color: #ccc;
//   }

//   /* When the checkbox is checked, add a blue background */
//   .hidecompleteCheckbox input:checked ~ .checkmark {
//     background-color: green !important;
//   }

//   /* Create the checkmark/indicator (hidden when not checked) */
//   .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
//   }

//   /* Show the checkmark when checked */
//   .hidecompleteCheckbox input:checked ~ .checkmark:after {
//     display: block;
//   }

//   /* Style the checkmark/indicator */
//   .hidecompleteCheckbox .checkmark:after {
//     left: 6px;
//     top: 3px;
//     width: 7px;
//     height: 10px;
//     border: solid white;
//     border-width: 0 2px 2px 0;
//     -webkit-transform: rotate(35deg);
//     -ms-transform: rotate(35deg);
//     transform: rotate(35deg);
//   }
.custom-switch {
  .react-switch {
    position: absolute !important;
    top: 9px;
    left: 28px;
    width: 10px;
    .react-switch-bg {
      height: 15px !important;
      width: 31px !important;
    }
    .react-switch-handle {
      width: 16px !important;
      height: 16px !important;
      top: 4px !important;
    }
  }
}
.un-invited-contributor {
  select {
    color: red;
  }
}

@media print {
  .work-plan .scrollableSection .bg-white {
    height: auto !important;
  }
  .workplan-link:not(:empty) + .work-plan-scroll .bg-white {
    height: auto !important;
    // height: 100vh !important;
  }
  .workplan-link:is(:empty) + .work-plan-scroll .bg-white {
    height: auto !important;
  }
  .work-plan-table {
    min-height: 0px !important;
  }
  .toggleableBtnGroup,
  .floatingBtn {
    display: none !important;
  }
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  table.communityMeetingTable {
    tr {
      th,
      td {
        &:nth-child(3) {
          width: 169px !important;
        }
        &:nth-child(5) {
          width: 100px !important;
        }
        &:nth-child(2) {
          width: 250px !important;
          min-width: 250px !important;
          max-width: 250px !important;
        }
        // &:nth-child(7) {
        //   width: 100px !important;
        // }
        // &:nth-child(8) {
        //   // border-left: 3px solid transparent;
        //   width: 15px !important;
        //   background-color: white;
        // }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .app-header {
    position: fixed;
    z-index: 1020;
    width: 100%;
    text-align: center;
    background-color: #fff;
  }
  .app-header .navbar-toggler {
    color: #fff;
  }
  .app-header .navbar-brand {
    position: relative !important;
    top: 0;
    left: 0 !important;
    margin-left: 0 !important;
  }
  // .app-body {
  //   margin-top: 55px;
  // }
  .sidebar {
    position: fixed;
    z-index: 1019;
    width: 200px;
    height: calc(100vh - 55px);
  }
  .sidebar-minimizer {
    display: none;
  }
  .aside-menu {
    position: fixed;
    height: 100%;
  }
}

.ownerInputSec {
  width: 164px;
  position: relative;

  .ownerInput {
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-repeat: no-repeat, repeat;
    background-position: right 2% top 55%, 0 0;
    background-size: 15px;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.headerInput {
  border: 1px solid #f0f0f0;
}
.headerInput:focus {
  // border:3px solid #96cbe3 !important;
  outline: none;
  border: none;
  box-shadow: 0 0 4px 2px #96cbe3 !important;
}

.floatingActionBtn {
  .close {
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #fff;
    border-radius: 50%;
    color: #fff;
    // top: 10px;
    // right: 10px;
    position: relative;
    transition: 0.5s;
    background-color: transparent;
    &:hover {
      transform: rotate(180deg);
      opacity: 1;
    }
  }
}

#custom-text-editor .rdw-editor-toolbar {
  padding: 0 !important;
}
